import React from "react";
import { connect } from "react-redux";
import DisplayScoreInfo from "./../ScoreInfo/DisplayScoreInfo";
import DisplayRewardRule from "../RewardRule/DisplayRewardRule";
import DisplayFaq from "../Faq/DisplayFaq";
import DisplayTermsAndConditions from "./../TermsAndConditions/DisplayTermsAndConditions";
import DisplayPrivacyPolicy from "../PrivacyPolicy/DisplayPrivacyPolicy";
import DisplaySecurePayment from "../SecurePayment/DisplaySecurePayment";
import DisplayAnimationArticle from "../AnimationArticle/DisplayAnimationArticle";
import DisplayContactUs from "../ContactUs/DisplayContactUs";
import DisplayAboutApp from "../AboutApp/DisplayAboutApp";
import DisplayAppGuide from  "../AppGuide/DisplayAppGuide";
import DisplayAppConfig from "../AppConfig/DisplayAppConfig";
import GenericConfig from "../GenericConfig/DisplayGenericConfig";
import {
  updateArticleMode,
  queryArticleFormOptions
 } from "../../../../actions/actions";
 import { permissions, renderArticleOptionsFilter } from "./../../../../helpers/Util";


class DisplayApplicationArticles extends React.PureComponent {

  constructor(props) {
    super(props);

    this.companyId = localStorage.getItem("company_id")
    this.group = "DE_CMS_ARTICLES"
    this.module = "CMS"
    this.allPermissions = []

    this.state = {
      articleMode: "-1"
    };
  }

  componentDidMount = () => {
    this.props.dispatch(queryArticleFormOptions({ body: {
      status: "Active",
      articlegroup: "Article"
    }}))
  }

  componentWillMount = () => {
    this.setState({ articleMode: this.props.queryData.articleMode || "-1" });
    this.allPermissions = permissions(this.module, this.group)
  };

  _renderNoData = () => {
    return (
      <React.Fragment>
        <div className="row noStoreform">
          <div className="col-lg-6 col-sm-12">
            <p className="noStoreHeader">Articles</p>
            <p className="noStoreBody">
              User can create or display different articles here.Choose article
              type to continue.
            </p>
          </div>
          <div className="col-lg-6 col-sm-12">
            <img
              className="noStoreImage"
              src={`${process.env.PUBLIC_URL}/assets/icons/img_vector_product.svg`}
            />
          </div>
        </div>
      </React.Fragment>
    );
  };

  _getComponent = () => {
    const { articleMode } = this.state;
    switch(articleMode) {
      case "Terms And Conditions":
        return <DisplayTermsAndConditions allPermissions={this.allPermissions}></DisplayTermsAndConditions>;
      case "Privacy Policy":
        return <DisplayPrivacyPolicy allPermissions={this.allPermissions}></DisplayPrivacyPolicy>;
      case "Secure Payment":
        return <DisplaySecurePayment allPermissions={this.allPermissions}></DisplaySecurePayment>;
      case "Animation Article":
        return <DisplayAnimationArticle allPermissions={this.allPermissions}></DisplayAnimationArticle>;
      case "Score Info":
        return <DisplayScoreInfo allPermissions={this.allPermissions}></DisplayScoreInfo>;
      case "Faq":
        return <DisplayFaq allPermissions={this.allPermissions}></DisplayFaq>;
      case "Contact Us":
        return <DisplayContactUs allPermissions={this.allPermissions}></DisplayContactUs>;
      case "About App":
        return <DisplayAboutApp allPermissions={this.allPermissions}></DisplayAboutApp>;
      case "Reward Rule":
        return <DisplayRewardRule allPermissions={this.allPermissions}></DisplayRewardRule>;
      case "App Guide":
        return <DisplayAppGuide allPermissions={this.allPermissions}></DisplayAppGuide>;
      case "App Config":
        return <DisplayAppConfig allPermissions={this.allPermissions}></DisplayAppConfig>;
      default:
        return <GenericConfig title={articleMode} allPermissions={this.allPermissions}/>;
    }
  };

  render() {
    const { articleOptions } = this.props.queryData;
    const articleopt = renderArticleOptionsFilter(articleOptions);
    return (
      <React.Fragment>
        <div>
          <div className="row">
            <div className="col-lg-8 col-sm-12">
              <h2 className="page-header">Articles Management</h2>
            </div>
            <div className="col-lg-4 col-sm-12 alignRight">
              <select
                name="example"
                className="simpleSearchSelect1"
                value={this.state.articleMode}
                onChange={event => {
                  this.setState({ articleMode: event.target.value });
                  console.log(updateArticleMode({ body: { mode: event.target.value } }), ">>>")
                  this.props.dispatch(
                    updateArticleMode({ body: { mode: event.target.value } })
                  );
                }}
                style={{ marginLeft: "2px" }}
              >
                {articleopt.length !== 0 &&
                  articleopt.map((option, index) => {
                    return (
                      <option key={index} value={option.id}>
                        {option.name}
                      </option>
                    );
                  })}
              </select>
            </div>
          </div>
        </div>
        {this.state.articleMode === "-1"
          ? this._renderNoData()
          : this._getComponent()}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    queryData: state.queryData,
    crudData: state.crudData,
    loginData: state.loginData
  };
};

export default connect(mapStateToProps)(DisplayApplicationArticles);
