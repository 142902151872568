import React from "react";
import { connect } from "react-redux";
import { Map, Marker, Popup, TileLayer } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { headers, rows } from "./tableCol";
import FixedColTable from "../../../components/FixedColTable";
import HorizontalBarChart from "./../../Charts/HorizontalBarChart";
import LineChart from "./../../Charts/LineChart";
import DatePicker from "react-date-picker";
import { toDate } from "./../../../helpers/Util";
import localforage from "localforage";
import "leaflet-offline";
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png")
});
function getData() {
  let data = [];

  return data;
}
class StoreAnalytics extends React.PureComponent {
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      data: getData(),
      oversalesfromdate: toDate("11-09-2019"),
      markers: [
        {
          key: "marker1",
          position: [1.327504, 103.929349],
          content: "Bedok "
        },
        {
          key: "marker2",
          position: [1.318642, 103.704839],
          content: "Jurong "
        },
        {
          key: "marker3",
          position: [1.307201, 103.851782],
          content: "Little India"
        }
      ]
    };
  }
  MyPopupMarker = ({ content, position }) => (
    <Marker position={position}>
      <Popup>{content}</Popup>
    </Marker>
  );

  onMapReady(map) {
    setTimeout(() => {
      map.invalidateSize();
    }, 0);
  }
  MyMarkersList = markers => {
    const items = markers.map((row, { id, ...props }) =>
      this.MyPopupMarker(row, id, { ...props })
    );
    return <React.Fragment>{items}</React.Fragment>;
  };
  componentDidMount() {
    this._isMounted = true;
    window.setInterval(() => {
      this.setState({
        data: getData()
      });
    }, 5000);
    const map = L.map("leaflet_map");
    const offlineLayer = L.tileLayer.offline(
      "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      localforage,
      {
        attribution:
          '&copy; <a href=”https://www.openstreetmap.org/copyright">OpenStreetMap</a>',
        subdomains: "abc",
        minZoom: 13,
        maxZoom: 19,
        crossOrigin: true
      }
    );
    offlineLayer.addTo(map);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }
  _renderDashboardSelect = (
    formattedObjArray,
    selectedOption,
    dropDownChange
  ) => {
    return (
      <select
        id="statusComp"
        className={`form-control chartSelect col-lg-2 col-sm-12 alignRight `}
        value={selectedOption}
        onChange={dropDownChange}
      >
        {formattedObjArray != undefined ? (
          formattedObjArray.map((t, i) => (
            <option key={i} value={t.id}>
              {t.name}
            </option>
          ))
        ) : (
          <option>No option</option>
        )}
      </select>
    );
  };
  doughnutchartonchange = value => {
    return console.log(value);
  };
  _renderQuickViewDiv = () => {
    return (
      <div className="row">
        <div className="col-lg-3 col-sm-12 ">
          <div className="card2">
            <img
              className="card2Image"
              src={`${process.env.PUBLIC_URL}/assets/icons/ic_db_store.svg`}
            />
            <p className="card2Text1">Total Stores</p>
            <p className="card2Text2">24</p>
          </div>
        </div>
        <div className="col-lg-3 col-sm-12  ">
          <div className="card2">
            <img
              className="card2Image"
              src={`${process.env.PUBLIC_URL}/assets/icons/ic_db_sales.svg`}
            />
            <p className="card2Text1">Total Sales</p>
            <p className="card2Text2">$2,212</p>
          </div>
        </div>
        <div className="col-lg-3 col-sm-12  ">
          <div className="card2">
            <img
              className="card2Image"
              src={`${process.env.PUBLIC_URL}/assets/icons/ic_db_products.svg`}
            />
            <p className="card2Text1">Total Products</p>
            <p className="card2Text2">123</p>
          </div>
        </div>
        <div className="col-lg-3 col-sm-12  ">
          <div className="card2">
            <img
              className="card2Image"
              src={`${process.env.PUBLIC_URL}/assets/icons/ic_db_transaction.svg`}
            />
            <p className="card2Text1">Total Transactions</p>
            <p className="card2Text2">212</p>
          </div>
        </div>
      </div>
    );
  };
  _renderMapView = () => {
    return (
      <div className="row">
        <div className="col-md-12 col-sm-12">
          <div className="chartLeft chartDiv">
            <div className="row noPadding noMargin">
              <div className="chartTitle col-lg-10 col-sm-12 alignLeft">
                Store Overview
              </div>
            </div>

            <div className="title_devide"></div>
            <div id="leaflet_map">
              <Map
                center={[1.307201, 103.851782]}
                zoom={2}
                style={{ height: "50vh", zIndex: 0 }}
                  scrollWheelZoom={false}
              >
                <TileLayer
                  attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                {this.MyMarkersList(this.state.markers)}
              </Map>
            </div>
          </div>
        </div>
      </div>
    );
  };
  _renderChartDiv1 = () => {
    return (
      <div className="row">
        <div className="col-md-12 col-sm-12 chartDiv">
          <div className="row noPadding noMargin">
            <div className="chartTitle col-lg-9 col-sm-12 alignLeft">
              Top Performing Store Locations
            </div>
            <div className=" col-lg-3 col-sm-12 noPadding noMargin aalignRight">
              <DatePicker
                onChange={value => {
                  this.setState({ oversalesfromdate: value });
                }}
                value={this.state.oversalesfromdate}
              />
            </div>
          </div>
          <div className="title_devide"></div>
          <HorizontalBarChart
            data={[20, 40, 30, 40, 50]}
            color="#4E84ED"
            labels={[
              "Bedok Store",
              "Jurong Store",
              "Little India Store",
              "Outram Store",
              "Changi Store"
            ]}
          />
        </div>
      </div>
    );
  };
  _renderChartDiv2 = () => {
    return (
      <div className="row">
        <div className="col-md-5 col-sm-12 noPadding noMargin">
          <div className="chartRight chartDiv" style={{ height: "29.7em" }}>
            <div className="row noPadding noMargin">
              <div className="chartTitle col-lg-10 col-sm-12 alignLeft">
                Best Sellers
              </div>
            </div>

            <div className="title_devide"></div>
            <div className="row">
              <div className="col-lg-6 col-sm-12 alignLeft">Bedok Store</div>
              <div
                className="col-lg-6 col-sm-12 alignRight"
                style={{ color: "blue" }}
              >
                $80.22
              </div>
            </div>
            <div className="title_devide"></div>
            <div className="row">
              <div className="col-lg-6 col-sm-12 alignLeft">Jurong Store</div>
              <div
                className="col-lg-6 col-sm-12 alignRight"
                style={{ color: "blue" }}
              >
                $20.33
              </div>
            </div>
            <div className="title_devide"></div>
            <div className="row">
              <div className="col-lg-6 col-sm-12 alignLeft">
                Little India Store
              </div>
              <div
                className="col-lg-6 col-sm-12 alignRight"
                style={{ color: "blue" }}
              >
                $22.33
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-7 col-sm-12">
          <div className="chartLeft chartDiv">
            <p className="chartTitle">Overall Sales</p>
            <div className="title_devide"></div>
            <LineChart
              data={[20, 20, 50, 30, 40, 20, 20, 50, 30, 40, 20, 20]}
              color="#5487D8"
              labels={[
                "Jan",
                "Feb",
                "Mar",
                "Apr",
                "May",
                "Jun",
                "Jul",
                "Aug",
                "Sep",
                "Oct",
                "Nov",
                "Dec"
              ]}
            />
          </div>
        </div>
      </div>
    );
  };
  _renderTable = () => {
    return (
      <div className="row">
        <div className="col-md-12 col-sm-12 noPadding noMargin">
          <FixedColTable
            columns={headers}
            rows={rows}
            tableheading="All Store Sales"
          ></FixedColTable>
        </div>
      </div>
    );
  };
  _renderForm = () => {
    return (
      <form>
        {this._renderQuickViewDiv()}
        {this._renderMapView()}
        {this._renderChartDiv1()}
        {this._renderChartDiv2()}
        {this._renderTable()}
      </form>
    );
  };
  render() {
    return (
      <div className="form_height">
        <div className="row form-container">
          <div className="col">{this._renderForm()}</div>
        </div>
      </div>
    );
  }
}

StoreAnalytics.propTypes = {};

const mapStateToProps = state => {
  return {
    queryData: state.queryData,
    responses: state.crudReducer
  };
};

export default connect(mapStateToProps)(StoreAnalytics);
