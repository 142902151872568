import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
//import 'rc-time-picker/assets/index.css';
import {
    crudReset,
    createStoreDevice,
    updateStoreDevice,
    storeDevicesSearchByFields,
    generateThumbnailApi,
} from '../../../../actions/actions';
import ConfirmAlert from '../../../../components/ConfirmAlert';
import StatusOptionComp from '../../../../components/StatusOptionComp';
import { produce } from 'immer';
import Select, { components } from 'react-select';
import DynamicTableImage from '../../../../components/DynamicTableImage';
import { InputSwitch } from 'primereact/inputswitch';

const Option = (props) => {
    return (
        <div>
            <components.Option {...props}>
                <input
                    type="checkbox"
                    checked={props.isSelected}
                    onChange={() => null}
                />{' '}
                <label>{props.label}</label>
            </components.Option>
        </div>
    );
};

class AddPosPeripheral extends React.PureComponent {
    editModeStatus = [
        { name: 'Select', id: '' },
        { name: 'New', id: 'New' },
        { name: 'Active', id: 'Active' },
        { name: 'InActive', id: 'InActive' },
    ];

    deviceGroupOptions = [
        { name: 'Select', id: '' },
        { name: 'Printer', id: 'printer' },
        { name: 'CashDrawer', id: 'cashdrawer' },
        { name: 'EFT', id: 'eft' },
        { name: "Light", id: "light" },
        { name: 'Virtual Printer', id: 'virtualprinter' },
        { name: 'Customer Display', id: 'customerdisplay' },
    ];

    printerVendorOptions = [{ name: 'Toshiba', id: 'TGCS' }];

    cashdrawerVendorOptions = [{ name: 'Toshiba', id: 'TGCS' }];

    eftVendorOptions = [
        { name: 'BBL', id: 'BBL' },
        { name: 'Rabbit', id: 'Rabbit' },
    ];

    comPortOptions = [
        { name: 'Select', id: '' },
        { name: 'COM1', id: 'COM1' },
        { name: 'COM2', id: 'COM2' },
        { name: 'COM3', id: 'COM3' },
        { name: 'COM4', id: 'COM4' },
        { name: 'COM5', id: 'COM5' },
        { name: 'COM6', id: 'COM6' },
        { name: 'COM7', id: 'COM7' },
        { name: 'COM8', id: 'COM8' },
        { name: 'COM9', id: 'COM9' },
        { name: 'COM10', id: 'COM10' },
        { name: 'COM11', id: 'COM11' },
        { name: 'COM12', id: 'COM12' },
    ];

    defaultimageField = [
        { uri: '', id: 0 },
        { uri: '', id: 1 },
        { uri: '', id: 2 },
        { uri: '', id: 3 },
        { uri: '', id: 4 },
        { uri: '', id: 5 },
        { uri: '', id: 6 },
        { uri: '', id: 7 },
        { uri: '', id: 8 },
        { uri: '', id: 9 },
    ];

    constructor(props) {
        super(props);
        this.state = {
            id: '',
            isEdit: false,
            isView: false,

            // Formrelated
            storeid: '',
            terminalid: '',
            deviceid: '',
            devicegroup: '',
            devicegroupName: '',
            brokerid: '',
            devicestatus: '',
            provider: '',
            title: '',
            peripharalid: '',
            devicebrokers: [],
            selectedDeviceBroker: {},

            status: 'New',
            sortorder: 1,
            additionalFields: {} /*[
                { field: "", value: "", id: 0 }
            ],*/,
            storelabel: '',

            portname: '',
            optionalPortname: '',
            ej: true,
            event_enabled: true,
            charset: 25,
            driver: 'JavaPOS',
            virtualDriver: 'Simulator',
            ejperprint: true,
            delay_init: 0,
            acktimeout: 0,
            responsetimeout: 0,
            logicalname: '',
            trxqr_print: true,
            providerOptions: [
                // {name: 'Select', id: ''}
            ],

            imagesgall: this.defaultimageField,
            nxtImgCounter: this.defaultimageField.length + 1,

            // Errors
            titleerror: '',
            storeiderror: '',
            brokeriderror: '',
            devicegrouperror: '',
            peripharaliderror: '',
            providererror: '',

            portnameerror: '',
            drivererror: '',
        };
    }

    getCreatePayload = () => {
        const {
            storeid,
            terminalid,
            title,
            devicegroup,
            status,
            brokerid,
            provider,
        } = this.state;

        let additionalfields = this.getAdditionalFields();

        let body = {
            storeid,
            terminalid,
            title,
            deviceid: this._getDeviceId(),
            devicegroup,
            brokerid,
            status,
            additionalfields,
            provider,
        };

        if (this._isEditMode()) {
            body['id'] = this.state.id;
        }

        const payload = {
            body: body,
        };

        return payload;
    };

    getAdditionalFields = () => {
        const { imagesgall } = this.state;

        if (this.state.devicegroup === 'printer') {
            return {
                ej: this.state.ej,
                charset: this.state.charset !== -1 ? this.state.charset : null,
                event_enabled: this.state.event_enabled,
                ejperprint: this.state.ejperprint,
                portname: this.state.portname,
                driver: this.state.driver,
                delay_init: this.state.delay_init,
                trxqr_print: this.state.trxqr_print,
                logicalname: this.state.logicalname,
                responsetimeout: this.state.responsetimeout,
                acktimeout: this.state.acktimeout
            };
        } else if (this.state.devicegroup === 'virtualprinter') {
            return {
                ej: this.state.ej,
                event_enabled: this.state.event_enabled,
                ejperprint: this.state.ejperprint,
                trxqr_print: this.state.trxqr_print,
                driver: 'Simulator',
                logicalname: this.state.logicalname,
                responsetimeout: this.state.responsetimeout,
                acktimeout: this.state.acktimeout,
                delay_init: this.state.delay_init
            };
        } else if (
            this.state.devicegroup === 'eft' || this.state.devicegroup === 'cashdrawer'
        ) {
            return {
                logicalname: this.state.logicalname,
                ej: this.state.ej,
                event_enabled: this.state.event_enabled,
                ejperprint: this.state.ejperprint,
                trxqr_print: this.state.trxqr_print,
                portname: this.state.portname,
                delay_init: this.state.delay_init,
                driver: this.state.driver,
                responsetimeout: this.state.responsetimeout,
                acktimeout: this.state.acktimeout,
            };
        } 
        else if (this.state.devicegroup === 'customerdisplay') {
            const imagegallery = [];
            imagesgall.forEach((obj, idx) => {
                if (obj.uri !== '') {
                    imagegallery.push(obj.uri);
                }
            });

            return {
                imagegallery: imagegallery.join(','),
            };
        } else {
            return null;
        }
    };

    getProvideOptions = devicegroup => {
        if (devicegroup === 'printer') {
            this.setState({
                providerOptions: this.printerVendorOptions,
                provider: this.printerVendorOptions[0].id
            });
        } else if (devicegroup === 'virtualprinter') {
            this.setState({
                providerOptions: this.printerVendorOptions,
                provider: this.printerVendorOptions[0].id,
            });
        } else if (devicegroup === 'eft') {
            this.setState({
                providerOptions: this.eftVendorOptions,
                provider: this.eftVendorOptions[0].id
            });
        } else if (devicegroup === 'cashdrawer') {
            this.setState({
                providerOptions: this.cashdrawerVendorOptions,
                provider: this.cashdrawerVendorOptions[0].id
            });
        }
    }

    updateProviderOptions = (devicegroup) => {
        if (devicegroup === 'printer') {
            this.setState({
                logicalname: 'DxPosPrinter',
                providerOptions: this.printerVendorOptions,
                provider: this.printerVendorOptions[0].id,
                portname: '',
                event_enabled: true,
                ej: true,
                ejperprint: true,
                trxqr_print: false,
                delay_init: 0,
                acktimeout: 0,
                responsetimeout: 0
            });
        } else if (devicegroup === 'virtualprinter') {
            this.setState({
                logicalname: 'DxPosVirtualPrinter',
                providerOptions: this.printerVendorOptions,
                provider: this.printerVendorOptions[0].id,
                portname: '',
                delay_init: 0,
                acktimeout: 0,
                responsetimeout: 0
            });
        } else if (devicegroup === 'eft') {
            this.setState({
                providerOptions: this.eftVendorOptions,
                provider: this.eftVendorOptions[0].id,
                portname: 'COM1',
                logicalname: 'EFT',
                acktimeout: 6000,
                responsetimeout: 120000,
                event_enabled: false,
                ej: false,
                ejperprint: false,
                trxqr_print: false
            });
        } else if (devicegroup === 'cashdrawer') {
            this.setState({
                providerOptions: this.cashdrawerVendorOptions,
                provider: this.cashdrawerVendorOptions[0].id,
                portname: '',
                logicalname: 'DxPosCashDrawer',
                delay_init: 5000,
                acktimeout: 0,
                responsetimeout: 0,
                event_enabled: false,
                ej: false,
                ejperprint: false,
                trxqr_print: false,
            });
        }
    };

    getPeripheralId = (devicegroup, deviceid) => {
        if (devicegroup === 'printer') {
            return deviceid.substring('PRT'.length);
        }
        if (devicegroup === 'cashdrawer') {
            return deviceid.substring('CD'.length);
        }
        if (devicegroup === 'eft') {
            return deviceid.substring('EFT'.length);
        }
        if (devicegroup === 'virtualprinter') {
            return deviceid.substring('VIRTUALPRT'.length);
        }
        if (devicegroup === 'customerdisplay') {
            return deviceid.substring('CUSTDISPLAY'.length);
        }
        // if (this.state.devicegroup === 'cashdrawer') { return 'CD' + threeDigitPeripharalId }
        // if (this.state.devicegroup === 'eft') { return 'EFT' + threeDigitPeripharalId }
    };

    getDeviceGroup = (devicegroup) => {
        const deviceGroup = this.deviceGroupOptions.filter(
            (e) => e.name === devicegroup
        );
        if (deviceGroup && deviceGroup.length > 0) {
            return deviceGroup[0].id;
        }
        return '';
    };

    _getDeviceId = () => {
        if (this.state.deviceid !== '') {
            return this.state.deviceid;
        }
        const deviceId = this.getDeviceId();
        if (this.state.devicegroup === 'printer') {
            return 'PRT' + deviceId;
        }
        if (this.state.devicegroup === 'cashdrawer') {
            return 'CD' + deviceId;
        }
        if (this.state.devicegroup === 'eft') {
            return 'EFT' + deviceId;
        }
        if (this.state.devicegroup === 'virtualprinter') {
            return 'VIRTUALPRT' + deviceId;
        }
        if (this.state.devicegroup === 'customerdisplay') {
            return 'CUSTDISPLAY' + deviceId;
        }
    };

    getDeviceId = () => {
        return Math.floor(Math.random() * 899999 + 100000);
    };

    _convertToImageGallery = (imageGalleryArray, size) => {
        imageGalleryArray = imageGalleryArray.map((e, i) => {
            return { uri: e, id: i };
        });
        for (let loop = imageGalleryArray.length; loop < size; loop++) {
            imageGalleryArray.push({ uri: '', id: loop });
        }
        for (let loop = 0; loop < size; loop++) {
            //reassign.
            imageGalleryArray[loop].id = loop;
        }
        return imageGalleryArray;
    };

    handleImageAddRow = (e) => {
        if (e) e.preventDefault();

        const rowLength = this.state.imagesgall.length;
        let lastele = this.state.imagesgall[rowLength - 1];
        const item = {
            uri: '',
            id: parseInt(lastele.id + 1),
        };

        this.setState(
            {
                imagesgall: [...this.state.imagesgall, item],
            },
            () => {
                console.log('Rows After Add', this.state.imagesgall);
            }
        );
    };

    handleImageInputChange = (event, dataField, row) => {
        let obj = {
            id: row.id,
            [dataField]:
                dataField === 'validation'
                    ? [event.target.value]
                    : dataField === 'uri'
                    ? event
                    : null, //event.target.value
        };

        this.setState(
            produce((draft) => {
                if (draft.imagesgall.length === 0) {
                    draft.imagesgall.push(obj);
                } else {
                    let found = false;
                    draft.imagesgall.forEach((command) => {
                        if (command.id === obj.id) {
                            Object.assign(command, obj);
                            found = true;
                        }
                    });
                    if (!found) {
                        draft.imagesgall.push(obj);
                    }
                }
            }),
            () => {}
        );
    };

    _deleteImageRow = (row) => {
        let rowId = row.id;
        const updateValues = this.state.imagesgall.filter(
            (value) => rowId !== value.id
        );
        updateValues.push({ uri: '', id: this.state.nxtImgCounter });

        this.setState(
            produce((draft) => {
                draft.nxtImgCounter = this.state.nxtImgCounter + 1;
                draft.imagesgall = updateValues;
            })
        );
    };

    _generateThumbnail = (fileToGenerateThumbnail) => {
        this.props.dispatch(crudReset());
        const payload = {
            body: [
                {
                    filepath: fileToGenerateThumbnail,
                    thumbnail: 'x1',
                    width: 150,
                    height: 150,
                    quality: 95,
                    hidden: 'N',
                },
                {
                    filepath: fileToGenerateThumbnail,
                    thumbnail: 'x2',
                    width: 200,
                    height: 200,
                    quality: 95,
                    hidden: 'N',
                },
                {
                    filepath: fileToGenerateThumbnail,
                    thumbnail: 'x3',
                    width: 250,
                    height: 250,
                    quality: 95,
                    hidden: 'N',
                },
            ],
        };
        this.props.dispatch(generateThumbnailApi(payload));
    };

    _saveDevice = (e) => {
        if (e) {
            e.preventDefault();
        }
        // console.log('check before save', this.getCreatePayload())
        // return
        if (this.validateForm()) {
            if (this._isEditMode()) {
                this.props.dispatch(
                    updateStoreDevice(this.getCreatePayload(), this.state.id)
                );
            } else {
                this.props.dispatch(createStoreDevice(this.getCreatePayload()));
            }
        } else {
            this.setState({
                formError: true,
                formErrorMsg: 'Clear all the errors to continue',
            });
            window.scrollTo(0, 0);
        }
    };

    _clearErrorMessages = () => {
        this.setState({
            formError: false,
            formErrorMsg: '',
            titleerror: '',
            devicegrouperror: '',
            brokeriderror: '',
            peripharaliderror: '',
        });
    };

    validateForm = () => {
        this._clearErrorMessages();
        const { title, storeid, devicegroup } = this.state;
        let valid = true;
        if (title.trim() === '') {
            this.setState({ titleerror: 'Please enter a title' });
            valid = false;
        }

        if (devicegroup.trim() === '') {
            this.setState({ devicegrouperror: 'Please select device group' });
            valid = false;
        }

        if (storeid.trim() === '') {
            this.setState({ storeiderror: 'Please enter storeid' });
            valid = false;
        }
        return valid;
    };

    _renderErrorMessage = () => {
        const { isSuccess, errormsg } = this.props.crudData;
        const { formError, formErrorMsg } = this.state;
        if (errormsg && !isSuccess) {
            return (
                <div className="alert alert-danger" role="alert">
                    {errormsg}
                </div>
            );
        } else if (formError && formErrorMsg) {
            return (
                <div className="alert alert-danger" role="alert">
                    {formErrorMsg}
                </div>
            );
        }
        return;
    };

    _isEditMode = () => {
        return this.state.isEdit;
    };

    _isViewMode = () => {
        return this.state.isView;
    };

    _renderMainButton = () => {
        if (this._isViewMode()) {
            return (
                <React.Fragment>
                    <Link
                        to="/ui/posperipherals"
                        className="btn btn-themes btn-rounded btn-sec link-sec-btn"
                    >
                        Close
                    </Link>
                </React.Fragment>
            );
        }

        const isEditMode = this._isEditMode();
        return (
            <React.Fragment>
                {!isEditMode && (
                    <button
                        type="submit"
                        className="btn btn-themes btn-rounded"
                        onClick={(e) => {
                            this._saveDevice(e);
                        }}
                    >
                        Save
                    </button>
                )}

                {isEditMode && (
                    <button
                        type="submit"
                        className="btn btn-themes btn-rounded"
                        onClick={(e) => this._saveDevice(e)}
                    >
                        Update
                    </button>
                )}

                <Link
                    to="/ui/posperipherals"
                    className="btn btn-themes btn-rounded btn-sec link-sec-btn"
                >
                    Cancel
                </Link>
            </React.Fragment>
        );
    };

    _renderDoubleColSubSecDivider = () => {
        return <div className="doubleCol-subsec-divider"></div>;
    };

    _renderSubSecDivider = () => {
        return <div className="subsec-divider"></div>;
    };

    _renderPrinterSettings = () => {
        return (
            <React.Fragment>
                {this._renderSubSecDivider()}
                <div className="row sub-title">Printer Settings</div>

                <div className="row">
                    <div className="form-group col-lg-4 col-sm-12">
                        <label htmlFor="logicalname" className="floatLeft">
                            Logical Name
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="logicalname"
                            name="logicalname"
                            value={this.state.logicalname}
                            onChange={(event) =>
                                this.setState({
                                    logicalname: event.target.value,
                                })
                            }
                        />
                    </div>
                    <div className="form-group col-lg-4 col-sm-12">
                        <label htmlFor="driver" className="floatLeft required">
                            Driver
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="storeid"
                            value={this.state.driver}
                            onChange={(event) =>
                                this.setState({ driver: event.target.value })
                            }
                        />
                        <div className="invalid-feedback">
                            {this.state.drivererror}
                        </div>
                    </div>
                    <div className="form-group col-lg-4 col-sm-12">
                        <label htmlFor="charset" className="floatLeft">
                            Charset
                        </label>
                        <input
                            type="number"
                            className="form-control"
                            id="charset"
                            value={this.state.charset}
                            onChange={(event) =>
                                this.setState({ charset: event.target.value })
                            }
                            min="-1"
                            disabled={this._isViewMode()}
                        />
                    </div>
                    <div className="form-group col-lg-4 col-sm-12">
                        <label htmlFor="delay_init" className="floatLeft">
                            Initialization Delay
                        </label>
                        <input
                            type="number"
                            className="form-control"
                            id="delay_init"
                            value={this.state.delay_init}
                            onChange={(event) =>
                                this.setState({
                                    delay_init: event.target.value,
                                })
                            }
                            min="0"
                            disabled={this._isViewMode()}
                        />
                    </div>

                    <div className="form-group col-lg-4 col-sm-12">
                        <label htmlFor="acktimeout" className="floatLeft">
                            Ack Timeout
                        </label>
                        <input
                            type="number"
                            className="form-control"
                            id="acktimeout"
                            value={this.state.acktimeout}
                            onChange={(event) =>
                                this.setState({
                                    acktimeout: event.target.value,
                                })
                            }
                            min="0"
                            disabled={this._isViewMode()}
                        />
                    </div>
                    <div className="form-group col-lg-4 col-sm-12">
                        <label htmlFor="responsetimeout" className="floatLeft">
                            Response Timeout
                        </label>
                        <input
                            type="number"
                            className="form-control"
                            id="responsetimeout"
                            value={this.state.responsetimeout}
                            onChange={(event) =>
                                this.setState({
                                    responsetimeout: event.target.value,
                                })
                            }
                            min="0"
                            disabled={this._isViewMode()}
                        />
                    </div>
                    <div className="form-group col-lg-4 col-sm-12">
                        <label className="col-form-label alignLeft">Port</label>
                        <StatusOptionComp
                            statusoption={this.comPortOptions}
                            selectedOption={this.state.portname}
                            callbackChangeFilter={(value) => {
                                this.setState({ portname: value });
                            }}
                        />
                        <div className="invalid-feedback">
                            {this.state.portnameerror}
                        </div>
                    </div>
                    <div className="row" style={{ marginTop: '10px' }}>
                        <div className="form-group col-lg-3 col-sm-12">
                            <label
                                htmlFor="event_enabled"
                                className="floatLeft w-100"
                            >
                                Event Enabled
                            </label>
                            <InputSwitch
                                checked={this.state.event_enabled}
                                onChange={(e) =>
                                    this.setState({
                                        event_enabled:
                                            !this.state.event_enabled,
                                    })
                                }
                            />
                        </div>
                        <div className="form-group col-lg-3 col-sm-12">
                            <label
                                htmlFor="ejperprint"
                                className="floatLeft w-100"
                            >
                                Ej Per Print
                            </label>
                            <InputSwitch
                                checked={this.state.ejperprint}
                                onChange={(e) =>
                                    this.setState({
                                        ejperprint: !this.state.ejperprint,
                                    })
                                }
                            />
                        </div>
                        <div className="form-group col-lg-3 col-sm-12">
                            <label htmlFor="ej" className="floatLeft w-100">
                                EJ
                            </label>
                            <InputSwitch
                                checked={this.state.ej}
                                onChange={(e) =>
                                    this.setState({ ej: !this.state.ej })
                                }
                            />
                        </div>
                        <div className="form-group col-lg-3 col-sm-12">
                            <label
                                htmlFor="trxqr_print"
                                className="floatLeft w-100"
                            >
                                Trx Qr Print
                            </label>
                            <InputSwitch
                                checked={this.state.trxqr_print}
                                onChange={(e) =>
                                    this.setState({
                                        trxqr_print: !this.state.trxqr_print,
                                    })
                                }
                            />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    };

    _renderVirtualPrinterSettings = () => {
        return (
            <React.Fragment>
                {this._renderSubSecDivider()}
                <div className="row sub-title">Virtual Printer Settings</div>

                <div className="row">
                    <div className="form-group col-lg-4 col-sm-12">
                        <label htmlFor="logicalname" className="floatLeft">
                            Logical Name
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="logicalname"
                            name="logicalname"
                            value={this.state.logicalname}
                            onChange={(event) =>
                                this.setState({
                                    logicalname: event.target.value,
                                })
                            }
                        />
                    </div>
                    <div className="form-group col-lg-4 col-sm-12">
                        <label htmlFor="driver" className="floatLeft required">
                            Driver
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="storeid"
                            value={this.state.virtualDriver}
                            onChange={(event) =>
                                this.setState({
                                    virtualDriver: event.target.value,
                                })
                            }
                            disabled={true}
                        />
                    </div>
                    <div className="form-group col-lg-4 col-sm-12">
                        <label className="col-form-label alignLeft">Port</label>
                        <StatusOptionComp
                            statusoption={this.comPortOptions}
                            selectedOption={this.state.portname}
                            callbackChangeFilter={(value) => {
                                this.setState({ portname: value });
                            }}
                        />
                        <div className="invalid-feedback">
                            {this.state.portnameerror}
                        </div>
                    </div>
                    <div className="form-group col-lg-4 col-sm-12">
                        <label htmlFor="delay_init" className="floatLeft">
                            Initialization Delay
                        </label>
                        <input
                            type="number"
                            className="form-control"
                            id="delay_init"
                            value={this.state.delay_init}
                            onChange={(event) =>
                                this.setState({
                                    delay_init: event.target.value,
                                })
                            }
                            min="0"
                            disabled={this._isViewMode()}
                        />
                    </div>
                    <div className="form-group col-lg-4 col-sm-12">
                        <label htmlFor="acktimeout" className="floatLeft">
                            Ack Timeout
                        </label>
                        <input
                            type="number"
                            className="form-control"
                            id="acktimeout"
                            value={this.state.acktimeout}
                            onChange={(event) =>
                                this.setState({
                                    acktimeout: event.target.value,
                                })
                            }
                            min="0"
                            disabled={this._isViewMode()}
                        />
                    </div>
                    <div className="form-group col-lg-4 col-sm-12">
                        <label htmlFor="responsetimeout" className="floatLeft">
                            Response Timeout
                        </label>
                        <input
                            type="number"
                            className="form-control"
                            id="responsetimeout"
                            value={this.state.responsetimeout}
                            onChange={(event) =>
                                this.setState({
                                    responsetimeout: event.target.value,
                                })
                            }
                            min="0"
                            disabled={this._isViewMode()}
                        />
                    </div>
                    <div className="row" style={{ marginTop: '10px' }}>
                        <div className="form-group col-lg-3 col-sm-12">
                            <label
                                htmlFor="event_enabled"
                                className="floatLeft w-100"
                            >
                                Event Enabled
                            </label>
                            <InputSwitch
                                checked={this.state.event_enabled}
                                onChange={(e) =>
                                    this.setState({
                                        event_enabled:
                                            !this.state.event_enabled,
                                    })
                                }
                            />
                        </div>
                        <div className="form-group col-lg-3 col-sm-12">
                            <label
                                htmlFor="ejperprint"
                                className="floatLeft w-100"
                            >
                                Ej Per Print
                            </label>
                            <InputSwitch
                                checked={this.state.ejperprint}
                                onChange={(e) =>
                                    this.setState({
                                        ejperprint: !this.state.ejperprint,
                                    })
                                }
                            />
                        </div>
                        <div className="form-group col-lg-3 col-sm-12">
                            <label htmlFor="ej" className="floatLeft w-100">
                                EJ
                            </label>
                            <InputSwitch
                                checked={this.state.ej}
                                onChange={(e) =>
                                    this.setState({ ej: !this.state.ej })
                                }
                            />
                        </div>
                        <div className="form-group col-lg-3 col-sm-12">
                            <label
                                htmlFor="trxqr_print"
                                className="floatLeft w-100"
                            >
                                Trx Qr Pprint
                            </label>
                            <InputSwitch
                                checked={this.state.trxqr_print}
                                onChange={(e) =>
                                    this.setState({
                                        trxqr_print: !this.state.trxqr_print,
                                    })
                                }
                            />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    };

    _renderCashdrawerSettings = () => {
        return (
            <React.Fragment>
                {this._renderSubSecDivider()}
                <div className="row sub-title">Cashdrawer Settings</div>

                <div className="row">
                    <div className="form-group col-lg-4 col-sm-12">
                        <label htmlFor="logicalname" className="floatLeft">
                            Logical Name
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="logicalname"
                            value={this.state.logicalname}
                            onChange={(event) =>
                                this.setState({
                                    logicalname: event.target.value,
                                })
                            }
                        />
                    </div>
                    <div className="form-group col-lg-4 col-sm-12">
                        <label htmlFor="driver" className="floatLeft required">
                            Driver
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="driver"
                            value={this.state.driver}
                            onChange={(event) =>
                                this.setState({ driver: event.target.value })
                            }
                            disabled={true}
                        />
                        <div className="invalid-feedback">
                            {this.state.drivererror}
                        </div>
                    </div>
                    <div className="form-group col-lg-4 col-sm-12">
                        <label className="col-form-label alignLeft">
                            Port
                        </label>
                        <StatusOptionComp
                            statusoption={this.comPortOptions}
                            selectedOption={this.state.portname}
                            callbackChangeFilter={(value) => {
                                this.setState({ portname: value });
                            }}
                        />
                        <div className="invalid-feedback">
                            {this.state.portnameerror}
                        </div>
                    </div>

                    <div className="form-group col-lg-4 col-sm-12">
                        <label htmlFor="delay_init" className="floatLeft">
                            Initialization Delay
                        </label>
                        <input
                            type="number"
                            className="form-control"
                            id="delay_init"
                            value={this.state.delay_init}
                            onChange={(event) =>
                                this.setState({
                                    delay_init: event.target.value,
                                })
                            }
                            min="0"
                            disabled={this._isViewMode()}
                        />
                    </div>
                    <div className="form-group col-lg-4 col-sm-12">
                        <label htmlFor="acktimeout" className="floatLeft">
                            Ack Timeout
                        </label>
                        <input
                            type="number"
                            className="form-control"
                            id="acktimeout"
                            value={this.state.acktimeout}
                            onChange={(event) =>
                                this.setState({
                                    acktimeout: event.target.value,
                                })
                            }
                            min="0"
                            disabled={this._isViewMode()}
                        />
                    </div>
                    <div className="form-group col-lg-4 col-sm-12">
                        <label htmlFor="responsetimeout" className="floatLeft">
                            Response Timeout
                        </label>
                        <input
                            type="number"
                            className="form-control"
                            id="responsetimeout"
                            value={this.state.responsetimeout}
                            onChange={(event) =>
                                this.setState({
                                    responsetimeout: event.target.value,
                                })
                            }
                            min="0"
                            disabled={this._isViewMode()}
                        />
                    </div>
                    <div className="row" style={{ marginTop: '10px' }}>
                        <div className="form-group col-lg-3 col-sm-12">
                            <label
                                htmlFor="event_enabled"
                                className="floatLeft w-100"
                            >
                                Event Enabled
                            </label>
                            <InputSwitch
                                checked={this.state.event_enabled}
                                onChange={(e) =>
                                    this.setState({
                                        event_enabled:
                                            !this.state.event_enabled,
                                    })
                                }
                            />
                        </div>
                        <div className="form-group col-lg-3 col-sm-12">
                            <label
                                htmlFor="ejperprint"
                                className="floatLeft w-100"
                            >
                                Ej Per Print
                            </label>
                            <InputSwitch
                                checked={this.state.ejperprint}
                                onChange={(e) =>
                                    this.setState({
                                        ejperprint: !this.state.ejperprint,
                                    })
                                }
                            />
                        </div>
                        <div className="form-group col-lg-3 col-sm-12">
                            <label htmlFor="ej" className="floatLeft w-100">
                                EJ
                            </label>
                            <InputSwitch
                                checked={this.state.ej}
                                onChange={(e) =>
                                    this.setState({ ej: !this.state.ej })
                                }
                            />
                        </div>
                        <div className="form-group col-lg-3 col-sm-12">
                            <label
                                htmlFor="trxqr_print"
                                className="floatLeft w-100"
                            >
                                Trx Qr Print
                            </label>
                            <InputSwitch
                                checked={this.state.trxqr_print}
                                onChange={(e) =>
                                    this.setState({
                                        trxqr_print: !this.state.trxqr_print,
                                    })
                                }
                            />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    };

    _renderEFTSettings = () => {
        return (
            <React.Fragment>
                {this._renderSubSecDivider()}
                <div className="row sub-title">EFT Settings</div>

                <div className="row">
                    <div className="form-group col-lg-4 col-sm-12">
                        <label htmlFor="logicalname" className="floatLeft">
                            Logical Name
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="logicalname"
                            name="logicalname"
                            value={this.state.logicalname}
                            onChange={(event) =>
                                this.setState({
                                    logicalname: event.target.value,
                                })
                            }
                        />
                    </div>
                    <div className="form-group col-lg-4 col-sm-12">
                        <label htmlFor="driver" className="floatLeft required">
                            Driver
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="storeid"
                            value={this.state.driver}
                            onChange={(event) =>
                                this.setState({ driver: event.target.value })
                            }
                            disabled={true}
                        />
                        <div className="invalid-feedback">
                            {this.state.drivererror}
                        </div>
                    </div>
                    <div className="form-group col-lg-4 col-sm-12">
                        <label className="col-form-label alignLeft required">
                            Port
                        </label>
                        <StatusOptionComp
                            statusoption={this.comPortOptions}
                            selectedOption={this.state.portname}
                            callbackChangeFilter={(value) => {
                                this.setState({ portname: value });
                            }}
                        />
                        <div className="invalid-feedback">
                            {this.state.portnameerror}
                        </div>
                    </div>
                    <div className="form-group col-lg-4 col-sm-12">
                        <label htmlFor="delay_init" className="floatLeft">
                            Initialization Delay
                        </label>
                        <input
                            type="number"
                            className="form-control"
                            id="delay_init"
                            value={this.state.delay_init}
                            onChange={(event) =>
                                this.setState({
                                    delay_init: event.target.value,
                                })
                            }
                            min="0"
                            disabled={this._isViewMode()}
                        />
                    </div>
                    <div className="form-group col-lg-4 col-sm-12">
                        <label htmlFor="acktimeout" className="floatLeft">
                            Ack Timeout
                        </label>
                        <input
                            type="number"
                            className="form-control"
                            id="acktimeout"
                            value={this.state.acktimeout}
                            onChange={(event) =>
                                this.setState({
                                    acktimeout: event.target.value,
                                })
                            }
                            min="0"
                            disabled={this._isViewMode()}
                        />
                    </div>
                    <div className="form-group col-lg-4 col-sm-12">
                        <label htmlFor="responsetimeout" className="floatLeft">
                            Response Timeout
                        </label>
                        <input
                            type="number"
                            className="form-control"
                            id="responsetimeout"
                            value={this.state.responsetimeout}
                            onChange={(event) =>
                                this.setState({
                                    responsetimeout: event.target.value,
                                })
                            }
                            min="0"
                            disabled={this._isViewMode()}
                        />
                    </div>
                    <div className="row" style={{ marginTop: '10px' }}>
                        <div className="form-group col-lg-3 col-sm-12">
                            <label
                                htmlFor="event_enabled"
                                className="floatLeft w-100"
                            >
                                Event Enabled
                            </label>
                            <InputSwitch
                                checked={this.state.event_enabled}
                                onChange={(e) =>
                                    this.setState({
                                        event_enabled:
                                            !this.state.event_enabled,
                                    })
                                }
                            />
                        </div>
                        <div className="form-group col-lg-3 col-sm-12">
                            <label
                                htmlFor="ejperprint"
                                className="floatLeft w-100"
                            >
                                Ej Per Print
                            </label>
                            <InputSwitch
                                checked={this.state.ejperprint}
                                onChange={(e) =>
                                    this.setState({
                                        ejperprint: !this.state.ejperprint,
                                    })
                                }
                            />
                        </div>
                        <div className="form-group col-lg-3 col-sm-12">
                            <label htmlFor="ej" className="floatLeft w-100">
                                EJ
                            </label>
                            <InputSwitch
                                checked={this.state.ej}
                                onChange={(e) =>
                                    this.setState({ ej: !this.state.ej })
                                }
                            />
                        </div>
                        <div className="form-group col-lg-3 col-sm-12">
                            <label
                                htmlFor="trxqr_print"
                                className="floatLeft w-100"
                            >
                                Trx Qr Print
                            </label>
                            <InputSwitch
                                checked={this.state.trxqr_print}
                                onChange={(e) =>
                                    this.setState({
                                        trxqr_print: !this.state.trxqr_print,
                                    })
                                }
                            />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    };

    _renderRabbitCardReaderSettings = () => {
        return (
            <React.Fragment>
                {this._renderSubSecDivider()}
                <div className="row sub-title">Rabbit reader Settings</div>

                <div className="row">
                    <div className="form-group col-lg-4 col-sm-12">
                        <label className="col-form-label alignLeft required">
                            Port
                        </label>
                        <StatusOptionComp
                            statusoption={this.comPortOptions}
                            selectedOption={this.state.portname}
                            callbackChangeFilter={(value) => {
                                this.setState({ portname: value });
                            }}
                        />
                        <div className="invalid-feedback">
                            {this.state.portnameerror}
                        </div>
                    </div>

                    <div className="form-group col-lg-4 col-sm-12">
                        <label htmlFor="delay_init" className="floatLeft">
                            Initialization Delay
                        </label>
                        <input
                            type="number"
                            className="form-control"
                            id="delay_init"
                            value={this.state.delay_init}
                            onChange={(event) =>
                                this.setState({
                                    delay_init: event.target.value,
                                })
                            }
                            min="0"
                            disabled={this._isViewMode()}
                        />
                    </div>
                </div>
            </React.Fragment>
        );
    };

    _renderCustomerDisplaySettings = () => {
        return (
            <React.Fragment>
                <div className="row sub-title">Image Gallery </div>
                <div className="row">
                    <div className="form-group col-lg-12 col-sm-12">
                        <DynamicTableImage
                            columns={[
                                {
                                    dataFieldId: 'uri',
                                    label: 'uri',
                                    addRow: true,
                                },
                            ]}
                            rows={this.state.imagesgall}
                            deleteRow={this._deleteImageRow}
                            addRow={this.handleImageAddRow}
                            generateThumbnail={this._generateThumbnail}
                            handleImageInputChange={this.handleImageInputChange}
                            mediaImageList={this.props.queryData.mediaList}
                        />
                    </div>
                </div>
            </React.Fragment>
        );
    };

    _renderSettings = () => {
        this._renderPrinterSettings();
        this._renderEFTSettings();
        this._renderCustomerDisplaySettings();
    };

    handleDeviceBrokerChange = (selected) => {
        this.setState({
            selectedDeviceBroker: selected,
        });
        if (selected) {
            this.setState({ brokerid: selected.value, brokeriderror: '' });
        } else {
            this.setState({ brokerid: '' });
        }
    };

    _renderBasicDetails = () => {
        return (
            <React.Fragment>
                <div className="row sub-title">Basic Information</div>
                <div className="row">
                    <div className="form-group col-lg-6 col-sm-12">
                        <label
                            htmlFor="storecode"
                            className="floatLeft required"
                        >
                            Store Id
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="storeid"
                            value={this.state.storeid}
                            onChange={(event) =>
                                this.setState({ storeid: event.target.value })
                            }
                            disabled={true}
                        />
                        <div className="invalid-feedback">
                            {this.state.storeiderror}
                        </div>
                    </div>

                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="title" className="floatLeft required">
                            Title
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="title"
                            value={this.state.title}
                            onChange={(event) =>
                                this.setState({ title: event.target.value })
                            }
                            disabled={this._isViewMode()}
                        />
                        <div className="invalid-feedback">
                            {this.state.titleerror}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-lg-6 col-sm-12">
                        <label htmlFor="peripharalid" className="floatLeft">
                            Device Id
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id="peripharalid"
                            value={this.state.peripharalid}
                            disabled={true}
                        />
                    </div>
                    <div className="form-group col-lg-6 col-sm-12">
                        <label className="col-form-label alignLeft required">
                            Device Group
                        </label>
                        {!this._isViewMode() && !this._isEditMode() && (
                            <StatusOptionComp
                                statusoption={this.deviceGroupOptions}
                                selectedOption={this.state.devicegroup}
                                callbackChangeFilter={(value) => {
                                    this.setState(
                                        { devicegroup: value },
                                        () => {
                                            this.updateProviderOptions(value);
                                        }
                                    );
                                }}
                            />
                        )}
                        {(this._isViewMode() || this._isEditMode()) && (
                            <input
                                type="text"
                                className="form-control"
                                id="devicegroup"
                                value={this.state.devicegroupName}
                                disabled={true}
                            />
                        )}
                        <div className="invalid-feedback">
                            {this.state.devicegrouperror}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-lg-6 col-sm-12">
                        <div className="row" style={{ marginBottom: 0 }}>
                            <label htmlFor="devicebroker" className="floatLeft">
                                Device Manager
                            </label>
                        </div>
                        <Select
                            options={this.state.devicebrokers}
                            isMulti={false}
                            closeMenuOnSelect={true}
                            hideSelectedOptions={false}
                            components={{
                                Option,
                            }}
                            onChange={this.handleDeviceBrokerChange}
                            allowSelectAll={false}
                            value={this.state.selectedDeviceBroker}
                        />
                        <div className="invalid-feedback">
                            {this.state.brokeriderror}
                        </div>
                    </div>

                    <div className="form-group col-lg-6 col-sm-12">
                        <label className="col-form-label alignLeft required">
                            Device Vendor
                        </label>
                        <StatusOptionComp
                            statusoption={this.state.providerOptions}
                            selectedOption={this.state.provider}
                            callbackChangeFilter={(value) => {
                                this.handleEftProvider(value)
                            }}
                        />
                        <div className="invalid-feedback">
                            {this.state.providererror}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    };

    handleEftProvider = value => {
        if (value === 'Rabbit') {
            this.setState({ 
                provider: value, 
                portname: 'COM2',
                acktimeout: 5000,
                responsetimeout: 60000
            });
        }
        else {
            this.setState({ 
                provider: value, 
                portname: 'COM1'
            });
        }
    }

    _renderOtherPropDetails = () => {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="form-group col-lg-12 col-sm-12">
                        <label htmlFor="status" className="floatLeft">
                            Status
                        </label>
                        <StatusOptionComp
                            statusoption={this.editModeStatus}
                            selectedOption={this.state.status}
                            callbackChangeFilter={(value) => {
                                this.setState({ status: value });
                            }}
                        />
                    </div>
                </div>
            </React.Fragment>
        );
    };

    _renderMainForm = () => {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-md-8 col-sm-12 noPadding noMargin">
                        <form className="splitFrmDiv">
                            {this._renderBasicDetails()}
                            {this.state.devicegroup === 'printer' &&
                                this._renderPrinterSettings()}
                            {this.state.devicegroup === 'eft' &&
                                this._renderEFTSettings()}
                            {/* {this.state.devicegroup === 'rabbit' && this._renderRabbitCardReaderSettings()} */}
                            {this.state.devicegroup === 'cashdrawer' &&
                                this._renderCashdrawerSettings()}
                            {this.state.devicegroup === 'customerdisplay' &&
                                this._renderCustomerDisplaySettings()}
                            {this.state.devicegroup === 'virtualprinter' &&
                                this._renderVirtualPrinterSettings()}
                        </form>
                    </div>
                    <div className="col-md-3 col-sm-12 doubleCol">
                        <form
                            className="splitFrmDivSec"
                            style={{ marginTop: '2em' }}
                        >
                            {this._renderOtherPropDetails()}
                        </form>
                    </div>
                </div>
            </React.Fragment>
        );
    };

    getLogicalName = (devicegroup) => {
        if (devicegroup === 'virtualprinter') {
            return 'DxPosVirtualPrinter'
        }
        else if (devicegroup === 'eft') {
            return 'EFT'
        }
        else if (devicegroup === 'printer') {
            return 'DxPosPrinter'
        }
        else if (devicegroup === 'CashDrawer') {
            return 'DxPosCashDrawer'
        }
        return devicegroup
    }

    componentDidMount = () => {
        this.props.dispatch(
            storeDevicesSearchByFields(
                `storeid=${this.state.storeid}&devicegroup=devicebroker&status=Active`
            )
        );
    };

    componentWillMount = () => {
        const storeid = localStorage.getItem('storeid');
        this.setState({ storeid });

        if (this.props.location && this.props.location.state) {
            const rec = this.props.location.state.item;
            const isEdit = this.props.location.state.isEdit;
            const isView = this.props.location.state.isView;

            //this.setState({isEdit, isView, isForApproval})
            console.log('record is', rec);
            if (rec) {
                const {
                    id,
                    storeid,
                    terminalid,
                    brokerid,
                    title,
                    devicegroup,
                    deviceid,
                    status,
                    devicestatus,
                    provider,
                    additionalfields,
                } = rec;
                this.setState(
                    produce((draft) => {
                        draft.id = id ? id : '';
                        draft.isEdit = isEdit;
                        draft.isView = isView;
                        draft.title = title ? title : '';
                        draft.storeid = storeid ? storeid : '';
                        draft.terminalid = terminalid ? terminalid : '';
                        draft.brokerid = brokerid ? brokerid : '';
                        draft.deviceid = deviceid ? deviceid : '';
                        draft.devicegroupName = devicegroup;
                        draft.devicegroup = devicegroup
                            ? this.getDeviceGroup(devicegroup)
                            : '';
                        this.getProvideOptions(draft.devicegroup);
                        draft.peripharalid = deviceid ? deviceid : ''; // this.getPeripheralId(draft.devicegroup, deviceid) : ''
                        draft.provider = provider ? provider : '';
                        draft.status = status ? status : '';
                        draft.devicestatus = devicestatus ? devicestatus : '';

                        draft.event_enabled =
                            additionalfields?.event_enabled ?? '';
                        draft.ejperprint = additionalfields?.ejperprint ?? '';
                        draft.charset = additionalfields?.charset ?? '';
                        draft.ej = additionalfields?.ej ?? '';
                        draft.trxqr_print = additionalfields?.trxqr_print ?? '';
                        draft.delay_init = additionalfields?.delay_init ?? '';
                        draft.logicalname = additionalfields?.logicalname ?? this.getLogicalName(draft.devicegroup);
                        draft.portname = additionalfields?.portname ?? '';

                        draft.additionalFields = additionalfields;

                        if (additionalfields !== null) {
                            if (
                                additionalfields['imagegallery'] &&
                                additionalfields['imagegallery'] !== ''
                            ) {
                                let imagearr = this._convertToImageGallery(
                                    additionalfields['imagegallery'].split(','),
                                    this.defaultimageField.length
                                );
                                draft.imagesgall =
                                    typeof imagearr !== 'undefined' &&
                                    imagearr.length !== 0
                                        ? imagearr
                                        : this.defaultimageField;
                            }
                            if (additionalfields['acktimeout']) {
                                draft.acktimeout =
                                    additionalfields['acktimeout'];
                            }
                            if (additionalfields['responsetimeout']) {
                                draft.responsetimeout =
                                    additionalfields['responsetimeout'];
                            }
                            if (additionalfields['logicalname']) {
                                draft.logicalname =
                                    additionalfields['logicalname'];
                            }
                            if (additionalfields['trxqr_print']) {
                                draft.trxqr_print =
                                    additionalfields['trxqr_print'];
                            }
                        }
                    })
                );
            }
        }
    };

    componentDidUpdate = (prevProps) => {
        const { storeDevices: devicebrokers } = this.props.queryData;
        const { storeDevices: prevDeviceBrokers } = prevProps.queryData;
        if (devicebrokers !== prevDeviceBrokers) {
            if (devicebrokers && devicebrokers.length > 0) {
                const options = devicebrokers.map((e) => ({
                    label: e.title,
                    value: e.deviceid,
                }));
                this.setState({ devicebrokers: options });
                if (this.state.brokerid !== '') {
                    const _selectedDeviceBroker = devicebrokers.filter(
                        (e) => e.deviceid === this.state.brokerid
                    );
                    if (
                        _selectedDeviceBroker &&
                        _selectedDeviceBroker.length > 0
                    ) {
                        const selectedDeviceBroker = _selectedDeviceBroker.map(
                            (e) => ({ label: e.title, value: e.deviceid })
                        );
                        this.setState({
                            selectedDeviceBroker: selectedDeviceBroker,
                        });
                    }
                }
            } else {
                this.setState({ devicebrokers: [], selectedDeviceBroker: {} });
            }
        }
    };

    hideModal = (e) => {
        e.preventDefault();
        this.props.dispatch(crudReset());
    };

    render() {
        const { isSuccess } = this.props.crudData;
        return (
            <React.Fragment>
                <div className="form_height">
                    <div className="row">
                        <div className="col-md-9">
                            <h2 className="page-header">
                                {this._isEditMode()
                                    ? 'Edit Peripheral'
                                    : this._isViewMode()
                                    ? 'View Peripheral Definition'
                                    : 'Add Peripheral'}
                            </h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            {this._renderErrorMessage()}
                            {this._renderMainForm()}
                        </div>
                    </div>

                    <div className="row btn-container form-button">
                        <div className="col-sm-12 col-lg-12">
                            {this._renderMainButton()}
                        </div>
                    </div>

                    <ConfirmAlert
                        show={isSuccess}
                        handleClose={this.hideModal}
                        to="/ui/posperipherals"
                        children={
                            <div style={{ padding: '2em', color: 'green' }}>
                                {this._isEditMode()
                                    ? 'Peripheral updated successfully'
                                    : 'Peripheral created successfully'}
                            </div>
                        }
                    />
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        queryData: state.queryData,
        crudData: state.crudData,
        loginData: state.loginData,
    };
};

export default connect(mapStateToProps)(AddPosPeripheral);
