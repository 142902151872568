import React from 'react';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

const options = [
    { name: 'Download Reports', id: '-1' },
    { name: 'Export to CSV', id: 'CSV' },
    { name: 'Export to PDF', id: 'PDF' },
];

class ExportButton extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            showdwnloadFormats: false,
            selectedOption: -1,
        };
    }

    exportPDF = () => {
        const unit = 'pt';
        const size = 'a4'; // Use A1, A2, A3 or A4
        const orientation = 'portrait'; // portrait or landscape
        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);
        doc.setFontSize(12);

        const title = this.props.title;
        const tabledata = this.props.tabledata;

        let content = {
            startY: 50,
            styles: { overflow: 'linebreak' },
            theme: 'grid',
            columnStyles: {
                4: { cellWidth: 70 },
                5: { cellWidth: 50 },
                6: { cellWidth: 50 },
            },
            body: tabledata,
        };

        doc.text(title, marginLeft, 40);
        doc.autoTable(content);
        doc.save(this.props.title.replace(/\s/g, '') + '.pdf');
    };

    convertToCSV(objArray) {
        var array =
            typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
        var str = '';
        for (var i = 0; i < array.length; i++) {
            var line = '';
            for (var index in array[i]) {
                if (line !== '') line += ',';

                line += array[i][index];
            }
            str += line + '\r\n';
        }
        return str;
    }

    exportCSV = () => {
        const items = this.props.tabledata;
        const fileTitle = this.props.title;
        // Convert Object to JSON
        var jsonObject = JSON.stringify(items);
        var csv = this.convertToCSV(jsonObject);
        var exportedFilenmae = fileTitle + '.csv' || 'export.csv';
        var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        if (navigator.msSaveBlob) {
            // IE 10+
            navigator.msSaveBlob(blob, exportedFilenmae);
        } else {
            var link = document.createElement('a');
            if (link.download !== undefined) {
                // feature detection
                // Browsers that support HTML5 download attribute
                var url = URL.createObjectURL(blob);
                link.setAttribute('href', url);
                link.setAttribute('download', exportedFilenmae);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    };

    handleChange = (event) => {
        const selectedvalue = event.target.value;
        if (selectedvalue === 'CSV') {
            return this.exportCSV();
        } else if (selectedvalue === 'PDF') {
            return this.exportPDF();
        } else {
            this.setState({ selectedOption: '-1' });
        }
        this.setState({ selectedOption: event.target.value });
    };

    render() {
        const { selectedOption } = this.state;
        return (
            <React.Fragment>
                <select
                    name="export"
                    value={selectedOption}
                    disabled={this.props.disabled || ''}
                    className="exportSearchSelect"
                    style={{ marginLeft: '2px' }}
                    onChange={this.handleChange}
                >
                    {options.length !== 0 &&
                        options.map((option, index) => {
                            const { name, id } = option;
                            return (
                                <option key={index} value={id}>
                                    {name}
                                </option>
                            );
                        })}
                </select>
            </React.Fragment>
        );
    }
}
export default ExportButton;
