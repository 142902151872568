import { takeLatest, takeEvery, call, put, take } from "redux-saga/effects";
import { queryApi } from "../api/services";

import * as constants from "../constants/constants";

export function* commonQuerySaga(action) {
  if (action.payload.noLoading !== true)
      yield put({ type: constants.API_PENDING });
  try {
      if (typeof action.payload.success === 'undefined') {
          console.error('Define Success for:' + action.type);
      }
      if (typeof action.payload.failure === 'undefined') {
          console.error('Define Failure for:' + action.type);
      }

      const response = yield call(queryApi, action.payload);
      if (response.data.status === '206') {
          // console.error('one', action.payload.success);
          // console.error('206 error from back end', action.payload.failure);
          yield put({
              type: action.payload.failure,
              data: [],
              errormsg:
                  response.data.message === '' ||
                  response.data.message === ' '
                      ? response.data.msgcode
                      : response.data.message,
          });
      } else if (
          response.data.status >= '400' &&
          response.data.status <= '500'
      ) {
          console.error(action.payload.success);
          console.error(response.data.status + 'Error', action.payload.url);
          yield put({
              type: action.payload.failure,
              data: [],
              errormsg: `No record found!`,
          });
      } else {
          if (response.data === '') {
              console.error(action.payload.success);
              console.error('200 error with no data  from back end');
              yield put({
                  type: action.payload.failure,
                  data: [],
                  errormsg: `No record found!`,
              });
          } else {
              yield put({
                  type: action.payload.success,
                  data: response.data,
              });
          }
      }
  } catch (error) {
      //console.error(error, 'error');
      if (error.response) {
        yield put({
          type: action.payload.failure,
          data: [],
          servererror: "Connection lost.Server is down or bad internet!!"
        });
      } else {
        let errorObject = JSON.parse(JSON.stringify(error));
        console.log(errorObject.message);
        yield put({
          type: action.payload.failure,
          data: [],
          servererror: "Connection lost.Server is down or bad internet!!"
        });
      }
      yield put({
        type: action.payload.failure,
        data: [],
        servererror: "Connection lost.Server is down or bad internet!!"
      });
      console.log('action.payload.failure', action.payload.failure);
  }
}


export function* querySaga() {
  yield takeLatest(constants.GET_MEDIA_LIST, commonQuerySaga);
  yield takeLatest(constants.GET_MEDIA_FOLDERS, commonQuerySaga);
  yield takeLatest(constants.GET_STATUS_CONFIG_BY_TITLE, commonQuerySaga);
  yield takeLatest(constants.GET_LANGUAGE_CONFIG_BY_TITLE, commonQuerySaga);
  yield takeLatest(constants.STORE_GROUP_BY_TITLE, commonQuerySaga);
  yield takeLatest(constants.MEMBER_PROPS_BY_TITLE, commonQuerySaga);
  yield takeLatest(constants.MEMBER_TXN_BY_TITLE, commonQuerySaga);
  yield takeLatest(constants.OPERATORS_BY_TITLE, commonQuerySaga);
  yield takeLatest(constants.FREQ_BY_TITLE, commonQuerySaga);
  yield takeLatest(constants.COUPON_VALIDITY_BY_TITLE, commonQuerySaga);
  yield takeLatest(constants.GET_COUNTRY_CONFIG_BY_TITLE, commonQuerySaga);
  yield takeLatest(constants.GET_BEACON_BRAND_BY_TITLE, commonQuerySaga);
  yield takeLatest(constants.GET_MOBILE_MESSAGE_TYPE_BY_TITLE, commonQuerySaga);
  yield takeLatest(constants.FETCH_ARTCILE_FORM, commonQuerySaga);
  yield takeLatest(constants.SEARCH_ARTICLE_INFO, commonQuerySaga);
  yield takeLatest(constants.ARTICLE_OPTIONS, commonQuerySaga);
  yield takeLatest(constants.SEARCH_MEMBER_PROFILE, commonQuerySaga);
  yield takeLatest(constants.SEARCH_MEMBER_PROFILE, commonQuerySaga);
  yield takeEvery(constants.SEARCH_DATA, commonQuerySaga);
  yield takeLatest(constants.SALES_SUMMARY_DATE, commonQuerySaga);
  yield takeLatest(constants.SALES_SUMMARY_HOUR, commonQuerySaga);
  yield takeLatest(constants.SALES_SUMMARY_MONTH, commonQuerySaga);
  yield takeLatest(constants.SALES_SUMMARY_YEAR, commonQuerySaga);
  yield takeLatest(constants.PRODUCT_SALES_SUMMARY, commonQuerySaga);
  yield takeLatest(constants.MEMBER_SALES_SUMMARY, commonQuerySaga);
  yield takeLatest(constants.MEMBER_DEVICE_MODEL, commonQuerySaga);
  yield takeLatest(constants.GUEST_DEVICE_MODEL, commonQuerySaga);
  yield takeEvery(constants.MEMBER_DEVICE_TYPE, commonQuerySaga);
  yield takeLatest(constants.GUEST_DEVICE_TYPE, commonQuerySaga);
  yield takeLatest(constants.NEW_MEMBER_DEVICE_TYPE, commonQuerySaga);
  yield takeLatest(constants.ACTIVE_MEMBER_DEVICE_TYPE, commonQuerySaga);
  yield takeLatest(constants.INACTIVE_MEMBER_DEVICE_TYPE, commonQuerySaga);
  yield takeLatest(constants.MEMBER_DEVICE_TYPE_DATE, commonQuerySaga);
  yield takeLatest(constants.GUEST_DEVICE_TYPE_DATE, commonQuerySaga);
  yield takeLatest(constants.CATEGORY_TREE, commonQuerySaga);
  yield takeLatest(constants.PROD_CAT_SEARCH, commonQuerySaga);
  yield takeLatest(constants.STORE_CAT_SEARCH, commonQuerySaga);
  yield takeLatest(constants.STORE_PRODUCTCODE, commonQuerySaga);
  yield takeLatest(constants.CART_SEARCH, commonQuerySaga);
  yield takeLatest(constants.GET_BY_ORDER_ID, commonQuerySaga);
  yield takeLatest(constants.GET_BY_ORDER_SESSION_ID, commonQuerySaga);
  yield takeLatest(constants.GET_BEACONS, commonQuerySaga);
  yield takeLatest(constants.GET_GEOFENCE, commonQuerySaga);
  yield takeLatest(constants.SEARCH_COUPONRULE, commonQuerySaga);
  yield takeLatest(constants.GET_COUPONCODE, commonQuerySaga);
  yield takeLatest(constants.PRODUCT_UPDATE, commonQuerySaga);
  yield takeLatest(constants.PRODUCT_GROUP, commonQuerySaga);
  yield takeLatest(constants.PRODUCT_SUGG, commonQuerySaga);
  yield takeLatest(constants.SEARCH_BY_STOREPRODID, commonQuerySaga);
  yield takeLatest(constants.INV_TRX_SEARCH, commonQuerySaga);
  yield takeLatest(constants.STORE_INV_SEARCH, commonQuerySaga);
  yield takeLatest(constants.PROD_KEY_SEARCH, commonQuerySaga);
  yield takeLatest(constants.SEARCH_CITY_BY_COUNTRY, commonQuerySaga);
  yield takeLatest(constants.SEARCH_STORE_BY_ID, commonQuerySaga);
  yield takeLatest(constants.SEARCH_PROPCONFIG, commonQuerySaga);
  yield takeLatest(constants.SEARCH_CNCONFIG, commonQuerySaga);
  yield takeLatest(constants.SEARCH_DTYPECONFIG, commonQuerySaga);
  yield takeLatest(constants.SEARCH_DELIVERYSLOT, commonQuerySaga);
  yield takeLatest(constants.SEARCH_USERPROFILE, commonQuerySaga);
  yield takeLatest(constants.MEMBER_DEVICE_INFO, commonQuerySaga);
  yield takeLatest(constants.SEARCH_USERROLESACCESS_BY_FIELDS, commonQuerySaga)
  yield takeLatest(constants.SEARCH_USERROLES_BY_FIELDS, commonQuerySaga)
  yield takeLatest(constants.SEARCH_USERACCESS_BY_FIELDS, commonQuerySaga)
  yield takeLatest(constants.SEARCH_USERGROUPS_BY_FIELDS, commonQuerySaga)
  yield takeLatest(constants.SEARCH_STORE_GROUP_BY_FIELDS, commonQuerySaga)
  yield takeLatest(constants.SEARCH_STORE_BY_FIELDS, commonQuerySaga)
  yield takeLatest(constants.SEARCH_COMPANY_BY_FIELDS, commonQuerySaga)
  yield takeLatest(constants.SEARCH_COUNTRY_BY_FIELDS, commonQuerySaga)
  yield takeLatest(constants.SEARCH_CITY_BY_FIELDS, commonQuerySaga)
  yield takeLatest(constants.SEARCH_PROMOTIONS_BY_FIELDS, commonQuerySaga)
  yield takeLatest(constants.SEARCH_PROMOTIONS_PGROUP_BY_FIELDS, commonQuerySaga)
  yield takeLatest(constants.SEARCH_PROMOTIONS_TGROUP_BY_FIELDS, commonQuerySaga)
  yield takeLatest(constants.SEARCH_STORE_DEVICES_FIELDS, commonQuerySaga)
  yield takeLatest(constants.SEARCH_CUST_DISPLAY, commonQuerySaga)
  yield takeLatest(constants.SEARCH_VIRTUAL_PRINTERS, commonQuerySaga)
  yield takeLatest(constants.SEARCH_STORE_TAGS_FIELDS, commonQuerySaga)
  yield takeLatest(constants.SEARCH_API_LOGS_FIELDS, commonQuerySaga)
  yield takeLatest(constants.SEARCH_API_LOGS_BY_ID, commonQuerySaga)
  yield takeLatest(constants.SEARCH_JOB_TASK_LOGS_FIELDS, commonQuerySaga)
  yield takeLatest(constants.SEARCH_JOB_TASK_LOGS_BY_ID, commonQuerySaga)
  yield takeLatest(constants.SEARCH_DATASYNC_LOGS_FIELDS, commonQuerySaga)
  yield takeLatest(constants.SEARCH_DATASYNC_LOGS_BY_ID, commonQuerySaga)
  yield takeLatest(constants.SEARCH_STORE_TERMINALS_FIELDS, commonQuerySaga)
  yield takeLatest(constants.STORE_PRODUCT_SEARCH, commonQuerySaga)
  yield takeLatest(constants.SEARCH_COUPON, commonQuerySaga)
  yield takeLatest(constants.SEARCH_COUPON_BY_FIELDS, commonQuerySaga)
  yield takeLatest(constants.COUPON_TRX_SEARCH, commonQuerySaga)
  yield takeLatest(constants.SEARCH_COUPON_TRX_BY_FIELDS, commonQuerySaga)
  yield takeLatest(constants.SEARCH_EXPORTED_COUPON_TRANSACTIONS, commonQuerySaga)
  yield takeLatest(constants.EXPORT_COUPON_TRANSACTIONS, commonQuerySaga)
  yield takeLatest(constants.SALES_DETAILS_REPORT, commonQuerySaga)
  yield takeLatest(constants.POS_MASTER_DETAILS_REPORT, commonQuerySaga)
  yield takeLatest(constants.TENDER_LISTING_REPORT, commonQuerySaga)
  yield takeLatest(constants.REFUND_DETAILS_REPORT, commonQuerySaga)
  yield takeLatest(constants.TAG_DETAILS_REPORT, commonQuerySaga)
  yield takeLatest(constants.SEARCH_PROP_CONFIG_FIELDS, commonQuerySaga)
  yield takeLatest(constants.SEARCH_PROP_CONFIG_MASTER_TRXTYPES, commonQuerySaga)
  yield takeLatest(constants.SEARCH_PROP_CONFIG_MASTER_TRXGROUPS, commonQuerySaga)
  yield takeLatest(constants.SEARCH_PROP_CONFIG_MASTER_TRXGRPTYPES, commonQuerySaga)
  yield takeLatest(constants.SEARCH_PROP_CONFIG_POINTRULETYPES, commonQuerySaga)
  yield takeLatest(constants.SEARCH_PROP_CONFIG_PAYTYPES, commonQuerySaga)
  yield takeLatest(constants.SEARCH_PROP_CONFIG_TENDERTAGTYPES, commonQuerySaga)
  yield takeLatest(constants.SEARCH_PROP_CONFIG_STORETENDERGROUP_TYPES, commonQuerySaga)
  yield takeLatest(constants.SEARCH_PROP_CONFIG_TOPUPTAGTYPES, commonQuerySaga)
  yield takeLatest(constants.SEARCH_STORE_OPEN_FIELDS, commonQuerySaga)
  yield takeLatest(constants.EXPORT_REPORT, commonQuerySaga)
  yield takeLatest(constants.PRODUCT_PRICE_DETAILS_REPORT, commonQuerySaga)
  yield takeLatest(constants.PRODUCT_SALES_SUMMARY_REPORT, commonQuerySaga)
  yield takeLatest(constants.EXPORT_EJT, commonQuerySaga)
  yield takeLatest(constants.SEARCH_EJ_BY_FIELDS, commonQuerySaga)
  yield takeLatest(constants.SEARCH_PROP_CONFIG_EJ_TRXTYPES, commonQuerySaga)
  yield takeLatest(constants.SPECIAL_DISCOUNT_TREE_SEARCH, commonQuerySaga)

  yield takeLatest(constants.SEARCH_TAXCONFIGURATION, commonQuerySaga)
  yield takeLatest(constants.SEARCH_TAXCONFIGURATION_FIELDS, commonQuerySaga)

  yield takeLatest(constants.SEARCH_SPECIALDISCOUNT, commonQuerySaga)
  yield takeLatest(constants.SEARCH_SPECIALDISCOUNT_PID, commonQuerySaga)
  yield takeLatest(constants.SEARCH_SPECIALDISCOUNT_FIELDS, commonQuerySaga)
  yield takeLatest(constants.SEARCH_STORE_BIRINFO_FIELDS_SILENT, commonQuerySaga)
  yield takeLatest(constants.SEARCH_TERMINAL_BIRINFO_FIELDS_SILENT, commonQuerySaga)
  yield takeLatest(constants.SEARCH_PROP_CONFIG_DATA_VALIDATION_TASKGROUPS, commonQuerySaga)
  yield takeLatest(constants.SEARCH_ITEMSALESTYPE_PROPCONFIG, commonQuerySaga)
  yield takeLatest(constants.SEARCH_SERVICE_REPORT_TYPES_PROPCONFIG, commonQuerySaga)
  yield takeLatest(constants.SEARCH_SALE_INVOICE_PAYMENT_TYPES, commonQuerySaga)

  yield takeLatest(constants.REPLENISHMENT_GET_ALL_REQUEST, commonQuerySaga)
}
