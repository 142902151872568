import React, { useEffect, useMemo } from "react";
import { Switch, Route } from "react-router-dom";
import { connect } from "react-redux";
import { queryPropertyConfigByFields } from "../actions/actions";
// Local imports
//import MediaManagement from "./../modules/cms/MediaManagement/MediaManagement";
import MediaManagement from "./../modules/media_mgmt/MediaManagement"
import AddEvent from "./../modules/cms/EventsManagement/AddEvent";
import DisplayStoreList from "./../modules/cms/StoreInformation/DisplayStoreList";
import DisplayEvents from "./../modules/cms/EventsManagement/DisplayEvents";
import AddStoreInformation from "./../modules/cms/StoreInformation/AddStoreInformation";
import ProductList from "../modules/selfServices/productCatalog/ProductList";
import AddNewProduct from "./../modules/selfServices/productCatalog/AddNewProduct";
import AddBeacon from "./../modules/communication/BeaconInformation/AddBeacon";
import DisplayBeacons from "../modules/communication/BeaconInformation/DisplayBeacons";
import DisplayGeoFence from "../modules/communication/Geofence/DisplayGeoFence";
import AddGeoFence from "./../modules/communication/Geofence/AddGeoFence";
import AddMobile from "./../modules/communication/Mobile/AddMobile";
import DisplayMobileInfo from "./../modules/communication/Mobile/DisplayMobileInfo";
import AddEmail from "./../modules/communication/Email/AddEmail";
import DisplayEmailInfo from "./../modules/communication/Email/DisplayEmailInfo";
import DisplayMemberProfile from "../modules/crm/MemberProfile/MemberProfileList";
import AddMemberProfile from "./../modules/crm/MemberProfile/AddMemberProfile";
import ProductCategory from "../modules/selfServices/productCategory/ProductCategory";
import AddProdCategory from "./../modules/selfServices/productCategory/AddProdCategory";
import ProductToStoreMapping from "./../modules/selfServices/storesProductMapping/ProductToStoreMapping";
import SalesCategory from "./../modules/system/SalesCategory/SalesCategory";
import AddSalesCategory from "./../modules/system/SalesCategory/AddSalesCategory";
import DisplayCarts from "./../modules/selfServices/cart/DisplayCarts";
import CartOperations from "./../modules/selfServices/cart/CartOperations";
import AddTermsAndConditions from "./../modules/cms/Articles/TermsAndConditions/AddTermsAndConditions";
import DisplayApplicationArticles from "./../modules/cms/Articles/ApplicationArticles/DisplayApplicationArticles";
import DisplayArticles from "./../modules/system/ArticleForm/DisplayArticles";
import DisplayArticleGroup from "./../modules/cms/WebArticles/ArticleGroup/DisplayArticleGroup";
import AddArticleTemplate from "./../modules/cms/WebArticles/ArticleTemplate/AddArticleTemplate";
import AddArticleForm from "./../modules/system/ArticleForm/AddArticleForm";
import AddPrivacyPolicy from "./../modules/cms/Articles/PrivacyPolicy/AddPrivacyPolicy";
import AddSecurePayment from "./../modules/cms/Articles/SecurePayment/AddSecurePayment";
import AddScoreInfo from "./../modules/cms/Articles/ScoreInfo/AddScoreInfo";
import AddAnimationArticle from "./../modules/cms/Articles/AnimationArticle/AddAnimationArticle";
import AddFaq from "./../modules/cms/Articles/Faq/AddFaq";
import AddAboutApp from "../modules/cms/Articles/AboutApp/AddAboutApp";
import AddContactUs from "../modules/cms/Articles/ContactUs/AddContactUs";
import AddGenericConfig from "../modules/cms/Articles/GenericConfig/AddGenericConfig";
import Dashboard from "../modules/Dashboard/Dashboard";
import DisplayPromotion from "../modules/cms/Promotions/DisplayPromotion";
import AddPromotion from "./../modules/cms/Promotions/AddPromotion";
import AddCoupon from "./../modules/Campaign/CouponManagement/AddCoupon";
import IssueCouponBatch from "./../modules/Campaign/CouponManagement/IssueCouponBatch";
import AddCouponRule from "../modules/Campaign/CouponRule/AddCouponRule";
import DisplayCouponRule from "./../modules/Campaign/CouponRule/DisplayCouponRule";
import MemberProfileReport from "./../modules/crm/MemberProfileReport/MemberProfileReport";
import IssueCoupon from "./../modules/crm/MemberCoupon/IssueCoupon";
import IssueMemberCoupons from "../modules/crm/MemberCoupon/IssueMemberCoupons";
import CustomerCouponList from "../modules/crm/MemberCoupon/CustomerCouponList";
import SurveyAnalytics from "./../modules/Survey/Analytics/SurveyAnalytics";
import CampaignAnalysis from "./../modules/Campaign/CampaignAnalysis/CampaignAnalysis";
import MobileAnalysis from "./../modules/crm/Analytics/MobileAnalysis";
import MemberAnalysis from "./../modules/crm/Analytics/MemberAnalysis";
import DisplayConfiguration from "./../modules/system/Configuration/DisplayConfiguration";
import AddConfiguration from "./../modules/system/Configuration/AddConfiguration";
import DisplayQuestions from "./../modules/Survey/Questions/DisplayQuestions";
import AddQuestions from "../modules/Survey/Questions/AddQuestions";
import DisplayCoupons from "./../modules/Campaign/CouponManagement/DisplayCoupons";
import DisplayWebForm from "./../modules/Survey/Webform/DisplayWebForm";
import AddWebform from "./../modules/Survey/Webform/AddWebform";
import SurveyReport from "./../modules/Survey/Report/SurveyReport";
import Editor from "./../modules/Misc/editor";
import DnD from "./../modules/Misc/DnD";
import AddProductInfo from "./../modules/cms/ProductInformation/AddProductInfo";
import DisplayProductInfo from "./../modules/cms/ProductInformation/DisplayProductInfo";
import AddProductAddon from "./../modules/cms/ProductAddon/AddProductAddon";
import DisplayProdcutAddon from "./../modules/cms/ProductAddon/DisplayProdcutAddon";
import DisplayCouponInfo from './../modules/cms/CouponInformation/DisplayCouponInfo';
import AddCouponInfo from './../modules/cms/CouponInformation/AddCouponInfo';
import DisplayCouponInventory from './../modules/Campaign/CouponInventory/DisplayCouponInventory';
import AddPointRule from './../modules/Campaign/PointRule/AddPointRule';
import DisplayPointRule from './../modules/Campaign/PointRule/DisplayPointRule';
import DisplayRabbitPointRule from "../modules/system/RabbitPoint/DisplayRabbitPointRule";
import AddRabbitPointRule from "../modules/system/RabbitPoint/AddRabbitPointRule";
import InventoryService from "../modules/selfServices/inventory/InventoryList";
import AddStoreInventory from './../modules/selfServices/inventory/AddStoreInventory';
import AddMapStore from "../modules/selfServices/storesProductMapping/AddMapStore";
import AddAppGuide from "../modules/cms/Articles/AppGuide/AddAppGuide";
import DisplayStoreHours from '../modules/system/Settings/DisplayStoreHours';
import AddRewardRule from "../modules/cms/Articles/RewardRule/AddRewardRule";
import AddAppConfig from "../modules/cms/Articles/AppConfig/AddAppConfig";
import AddSalesPromotions from "../modules/selfServices/salePromotions/AddSalesPromotions";
import DisplaySalesPromotions from "../modules/selfServices/salePromotions/DisplaySalesPromotions";
import DisplayPropConfig from '../modules/cms/PropertyConfiguration/DisplayPropConfig';
import AddPropConfig from '../modules/cms/PropertyConfiguration/AddPropConfig';
import DisplayWordTranslation from '../modules/cms/WordTranslation/DisplayWordTranslation';
import AddWordTranslation from '../modules/cms/WordTranslation/AddWordTranslation';
import AddDeliveryArea from '../modules/selfServices/DeliveryArea/AddDeliveryArea';
import DisplayDeliveryArea from '../modules/selfServices/DeliveryArea/DisplayDeliveryArea';
import AddDeliverySlot from '../modules/selfServices/DeliverySlot/AddDeliverySlot';
import DisplayDeliverySlot from '../modules/selfServices/DeliverySlot/DisplayDeliverySlot';
import DisplayTaskService from '../modules/selfServices/TaskService/DisplayTaskService';
import AddTaskService from '../modules/selfServices/TaskService/AddTaskService';
import ReadyToShip from '../modules/selfServices/TaskService/ReadyToShip';
import DisplayOrderShip from '../modules/selfServices/OrderShipping/DisplayOrderShip';
import AddOrderShip from '../modules/selfServices/OrderShipping/AddOrderShip';
import DisplayOrderReturn from '../modules/selfServices/OrderReturn/DisplayOrderReturn';
import AddOrderReturn from '../modules/selfServices/OrderReturn/AddOrderReturn';
import ShipReturnTask from '../modules/selfServices/OrderShipAndReturn/ShipReturnTask';
import SalesProductReport from "../modules/selfServices/Report/SalesProductReport";
import SalesTrackerReport from "../modules/selfServices/Report/SalesTrackerReport";
import MemberReport from "../modules/selfServices/Report/MemberReport";
import AddSalesDocument from "../modules/selfServices/SalesDocument/AddSalesDocument";
import DisplaySalesDocument from "../modules/selfServices/SalesDocument/SalesDocumentList";
import CustomerRegistration from "../modules/selfServices/TaskAssigner/CustomerRegistration";
import AddCustomerRegistration from "../modules/selfServices/TaskAssigner/CustomerRegistration/AddCustomer";
import TechnicianRegistration from "../modules/selfServices/TaskAssigner/TechnicianRegistration";
import AddTechnicianRegistration from "../modules/selfServices/TaskAssigner/TechnicianRegistration/AddTechnician";
import TechnicianManagerRegistration from "../modules/selfServices/TaskAssigner/TechnicianManagerRegistration";
import AddTechnicianManagerRegistration from "../modules/selfServices/TaskAssigner/TechnicianManagerRegistration/AddTechnicianManager";
import ItemRegistration from "../modules/selfServices/TaskAssigner/ItemRegistration";
import AddItemRegistration from "../modules/selfServices/TaskAssigner/ItemRegistration/AddItem";

import Users from "../modules/system/Users/Users";
import AddUser from "../modules/system/Users/AddUser";
import UserGroups from "../modules/system/UserGroups/UserGroups";
import AddUserGroup from "../modules/system/UserGroups/AddUserGroup";
import UserRoles from "../modules/system/UserRoles/UserRoles";
import AddRole from "../modules/system/UserRoles/AddRole";
import AddPromoPurchaseGroup from "../modules/selfServices/salePromotions/AddPromoPurchaseGroup";
import DisplayPromoPurchaseGroups from "../modules/selfServices/salePromotions/DisplayPromoPurchaseGroups";
import DisplayStoreTerminals from "../modules/system/StoreMgmt/TerminalMgmt/DisplayStoreTerminals";
import AddStoreTerminal from "../modules/system/StoreMgmt/TerminalMgmt/AddStoreTerminal";
import DisplayDeviceBrokers from "../modules/system/StoreMgmt/DeviceBrokerMgmt/DisplayDeviceBrokers";
import AddDeviceBroker from "../modules/system/StoreMgmt/DeviceBrokerMgmt/AddDeviceBroker";
import DisplayPosPeripherals from "../modules/system/StoreMgmt/PeripheralsMgmt/DisplayPosPeripherals";
import AddPosPeripheral from "../modules/system/StoreMgmt/PeripheralsMgmt/AddPosPeripheral";
import StoreOptionList from "../modules/system/StoreMgmt/StoreOptions/StoreOptionList";
import AddStoreOptions from "../modules/system/StoreMgmt/StoreOptions/AddStoreOptions";
import TerminalOptionList from "../modules/system/StoreMgmt/TerminalOptions/TerminalOptionList";
import AddTerminalOptions from "../modules/system/StoreMgmt/TerminalOptions/AddTerminalOptions";
import ApiLog from "../modules/system/Logs/ApiLog";
import JobTaskLog from "../modules/system//Logs/JobTaskLog";
import DataSyncLog from "../modules/system//Logs/DataSyncLog";
import DisplayCouponTransaction from "../modules/Campaign/CouponTransaction/DisplayCouponTransaction";
import IssueAndExportCoupon from "../modules/Campaign/CouponTransaction/IssueAndExportCoupon";
import PosMasterReport from "../modules/reports/PosMasterReport";
import TenderListingReport from "../modules/reports/TenderListingReport";
import RefundReport from "../modules/reports/RefundReport";
import RabbitMemberReport from "../modules/reports/RabbitMemberReport";
import ProductPriceDetailsReport from "../modules/reports/ProductPriceDetailsReport";
import SalesDetailsReport from "../modules/reports/SalesDetailsReport";
import ProductSalesSummary from "../modules/reports/ProductSalesSummary";
import SalesAnalytics from "../modules/reports/SalesAnalytics";
import StoreAnalytics from '../modules/cms/Analytics/StoreAnalytics'
import EJ from "../modules/reports/EJ";
import DisplayStoreGroups from "../modules/system/StoreMgmt/StoreGroups/DisplayStoreGroups";
import AddStoreGroup from "../modules/system/StoreMgmt/StoreGroups/AddStoreGroup";
import DisplayStores from "../modules/system/StoreMgmt/Stores/DisplayStores";
import AddStore from "../modules/system/StoreMgmt/Stores/AddStore";
import DisplayStoreTenders from "../modules/system/StoreMgmt/StoreOptions/DisplayStoreTenders";
import AddStoreTenders from "../modules/system/StoreMgmt/StoreOptions/AddStoreTenders";
import DisplayTerminalTenders from "../modules/system/StoreMgmt/TerminalOptions/DisplayTerminalTenders";
import AddTerminalTenders from "../modules/system/StoreMgmt/TerminalOptions/AddTerminalTenders";
import DailySalesReport from "../modules/reports/DailySalesReport";
import MonthlySalesReport from "../modules/reports/MonthlySalesReport";

import TaxConfiguration from "../modules/system/StoreMgmt/TaxConfiguration/DisplayTaxConfiguration";
import AddTaxConfiguration from "../modules/system/StoreMgmt/TaxConfiguration/AddTC";
import SpecialDiscount from "../modules/system/StoreMgmt/SpecialDiscount/DisplaySpecialDiscount";
import AddSpecialDiscount from "../modules/system/StoreMgmt/SpecialDiscount/AddSD";
import BIRTermSalesSummary from "../modules/reports/BIRTermSalesSummary";
import BIRSpecialDiscountReport from "../modules/reports/BIRSpecialDiscountReport";
import DataValidation from "../modules/reports/DataValidation";
import ServicesReport from "../modules/reports/ServicesReport";
import CouponTransactionReport from "../modules/reports/CouponTransactionReport";

import DisplayCouponRedemption from "../modules/Campaign/CouponRedemption/DisplayCouponRedemption"
import AddCouponRedemptionRule from "../modules/Campaign/CouponRedemption/AddCouponRedemptionRule";
import SalesSyncExceptionReport from "../modules/reports/SalesSyncExceptionReport";
import PromotionList from "../modules/selfServices/salePromotions/PromotionList";
import AddSalesPromotionsNew from "../modules/selfServices/salePromotions/AddSalesPromotionsNew";
import EipMessage from "../modules/system//Logs/EipMessage";
import CartSyncReport from "../modules/reports/CartSyncReport";
import BIRSalesSummaryReport from "../modules/reports/BIRSalesSummaryReport";
import SpTerminalSummaryReport from "../modules/reports/SpTerminalSummaryReport";
import ReplenishmentList from "../modules/replenishment/ReplenishmentList";
import ReplenishmentDetail from "../modules/replenishment/ReplenishmentDetail";

//sale service
import SalesReturnList from "../modules/ns-extension/SalesReturnList";
import SalesReturnDetail from "../modules/ns-extension/SalesReturnDetail";
import CollectionInvoiceList from "../modules/ns-extension/CollectionInvoiceList";
import CollectionInvoiceDetail from "../modules/ns-extension/CollectionInvoiceDetail";

//NS extension
import SaleStockReport from "../modules/ns-extension/SaleStockReport";
import FileIntegrationReport from "../modules/ns-extension/FileIntegrationReport";
import SaleReturnPrice from "../modules/ns-extension/SaleReturnPrice";


import CouponUtilization from '../modules/crm/MemberCoupon/CouponUtilization'

const MainRouterPage = (props) => {
  const { path } = props.match;

  useEffect(() => {

    // props.dispatch(crudReset());
    // props.dispatch(resetAction());

    const requestBody = {
      body: {
        propcode: "Language",
        //category: "Config"
      }
    };
    props.dispatch(
      queryPropertyConfigByFields(
        requestBody,
        "GET_LANGUAGE_CONFIG_BY_TITLE",
        "LANGUAGE_CONFIG_BY_TITLE_SUCCESS",
        "LANGUAGE_CONFIG_BY_TITLE_FAILURE"
      )
    );
  }, [props.location.pathname])

  const _renderSwitch = useMemo(() => (
    <Switch>
      <Route exact path={`${path}`} component={() => <Dashboard />} />
      <Route
        exact
        path={`${path}/test`}
        component={() => <CouponUtilization match={props.match} />}
      />
      <Route
        exact
        path={`${path}/media-management`}
        component={() => <MediaManagement match={props.match} />}
      />
      <Route
        exact
        path={`${path}/store-information`}
        component={() => <DisplayStoreList match={props.match} />}
      />
      <Route
        exact
        path={`${path}/store-information/addStoreInfo`}
        component={AddStoreInformation}
      />
      <Route
        exact
        path={`${path}/product-info`}
        component={DisplayProductInfo}
      />
      <Route
        exact
        path={`${path}/product-info/addproductinfo`}
        component={AddProductInfo}
      />
      <Route
        exact
        path={`${path}/product-addon`}
        component={() => <DisplayProdcutAddon match={props.match} />}
      />
      <Route
        exact
        path={`${path}/product-addon/addproductaddon`}
        component={AddProductAddon}
      />
      <Route
        exact
        path={`${path}/coupon-info`}
        component={() => <DisplayCouponInfo match={props.match} />}
      />
      <Route
        exact
        path={`${path}/coupon-info/addcouponinfo`}
        component={AddCouponInfo}
      />
      <Route
        exact
        path={`${path}/events-management`}
        component={() => <DisplayEvents match={props.match} />}
      />
      <Route
        exact
        path={`${path}/events-management/addNew`}
        component={AddEvent}
      />
      <Route
        exact
        path={`${path}/article-form`}
        component={() => <DisplayArticles match={props.match} />}
      />
      <Route
        exact
        path={`${path}/article-form/addNew`}
        component={AddArticleForm}
      />
      <Route
        exact
        path={`${path}/articles`}
        component={() => (
          <DisplayApplicationArticles match={props.match} />
        )}
      />
      <Route
        exact
        path={`${path}/page-articles`}
        component={() => (
          <DisplayArticleGroup match={props.match} />
        )}
      />
      <Route
        exact
        path={`${path}/page-articles/addNew`}
        component={AddArticleTemplate}
      />
      <Route
        exact
        path={`${path}/store-hours`}
        component={DisplayStoreHours}
      />
      <Route
        exact
        path={`${path}/property-config`}
        component={DisplayPropConfig}
      />
      <Route
        exact
        path={`${path}/property-config/addNew`}
        component={AddPropConfig}
      />
      <Route
        exact
        path={`${path}/word-translation`}
        component={DisplayWordTranslation}
      />
      <Route
        exact
        path={`${path}/word-translation/addNew`}
        component={AddWordTranslation}
      />
      <Route
        exact
        path={`${path}/delivery-area/addNew`}
        component={AddDeliveryArea}
      />
      <Route
        exact
        path={`${path}/delivery-area`}
        component={DisplayDeliveryArea}
      />
      <Route
        exact
        path={`${path}/operation-slot/addNew`}
        component={AddDeliverySlot}
      />
      <Route
        exact
        path={`${path}/operation-slot`}
        component={DisplayDeliverySlot}
      />
      <Route
        exact
        path={`${path}/orders-fulfillment/addNew`}
        component={AddTaskService}
      />
      <Route
        exact
        path={`${path}/orders-fulfillment/readytoship`}
        component={ReadyToShip}
      />
      <Route
        exact
        path={`${path}/orders-fulfillment/shipping`}
        component={DisplayOrderShip}
      />
      <Route
        exact
        path={`${path}/orders-fulfillment/shipping/addNew`}
        component={AddOrderShip}
      />
      <Route
        exact
        path={`${path}/orders-fulfillment/shipping-return`}
        component={DisplayOrderReturn}
      />
      <Route
        exact
        path={`${path}/orders-fulfillment/shipping-return/addNew`}
        component={AddOrderReturn}
      />
      <Route
        exact
        path={`${path}/order-fulfillment`}
        component={ShipReturnTask}
      />
      <Route
        exact
        path={`${path}/orders-fulfillment`}
        component={DisplayTaskService}
      />
      <Route
        exact
        path={`${path}/terms-conditions/addNew`}
        component={AddTermsAndConditions}
      />
      <Route
        exact
        path={`${path}/secure-payment/addNew`}
        component={AddSecurePayment}
      />
      <Route
        exact
        path={`${path}/app-guide/addNew`}
        component={AddAppGuide}
      />
      <Route
        exact
        path={`${path}/reward-rule/addNew`}
        component={AddRewardRule}
      />
      <Route
        exact
        path={`${path}/app-config/addNew`}
        component={AddAppConfig}
      />
      <Route
        exact
        path={`${path}/privacy-policy/addNew`}
        component={AddPrivacyPolicy}
      />
      <Route
        exact
        path={`${path}/animation-article/addNew`}
        component={AddAnimationArticle}
      />
      <Route
        exact
        path={`${path}/score-info/addNew`}
        component={AddScoreInfo}
      />
      <Route exact path={`${path}/faq/addNew`} component={AddFaq} />
      <Route
        exact
        path={`${path}/about-app/addNew`}
        component={AddAboutApp}
      />
      <Route
        exact
        path={`${path}/contact-us/addNew`}
        component={AddContactUs}
      />
      <Route
        exact
        path={`${path}/generic-config/addNew`}
        component={AddGenericConfig}
      />
      <Route
        exact
        path={`${path}/product-catalog`}
        component={() => <ProductList />}
      />
      <Route
        exact
        path={`${path}/product-catalog/addNew`}
        component={AddNewProduct}
      />
      <Route
        exact
        path={`${path}/product-category`}
        component={ProductCategory}
      />
      <Route
        exact
        path={`${path}/product-category/addNew`}
        component={AddProdCategory}
      />
      <Route
        exact
        path={`${path}/tax-configuration`}
        component={TaxConfiguration}
      />
      <Route
        exact
        path={`${path}/tax-configuration/addNew`}
        component={AddTaxConfiguration}
      />
      <Route
        exact
        path={`${path}/specialdiscount`}
        component={SpecialDiscount}
      />
      <Route
        exact
        path={`${path}/specialdiscount/addNew`}
        component={AddSpecialDiscount}
      />
      {/* Communication */}
      <Route
        exact
        path={`${path}/beacon-information`}
        component={() => <DisplayBeacons match={props.match} />}
      />
      <Route
        exact
        path={`${path}/beacon-information/addNew`}
        component={AddBeacon}
      />
      <Route
        exact
        path={`${path}/geo-fence`}
        component={() => <DisplayGeoFence match={props.match} />}
      />
      <Route
        exact
        path={`${path}/geo-fence/addNew`}
        component={AddGeoFence}
      />
      <Route
        exact
        path={`${path}/email`}
        component={() => <DisplayEmailInfo match={props.match} />}
      />
      <Route exact path={`${path}/email/addNew`} component={AddEmail} />
      <Route
        exact
        path={`${path}/mobile-notification`}
        component={() => <DisplayMobileInfo match={props.match} />}
      />
      <Route
        exact
        path={`${path}/mobile-notification/addNew`}
        component={AddMobile}
      />
      <Route
        exact
        path={`${path}/member-profile`}
        component={DisplayMemberProfile}
      />
      <Route
        exact
        path={`${path}/member-profile/addNew`}
        component={AddMemberProfile}
      />
      <Route
        exact
        path={`${path}/member-profile-report`}
        component={MemberProfileReport}
      />
      <Route
        exact
        path={`${path}/member-coupon/list`}
        component={CustomerCouponList}
      />
      <Route
        exact
        path={`${path}/member-coupon`}
        component={IssueMemberCoupons}
      />
      <Route
        exact
        path={`${path}/member-coupon/issue-coupon`}
        component={IssueCoupon}
      />
      <Route
        exact
        path={`${path}/sales-category`}
        component={SalesCategory}
      />
      <Route
        exact
        path={`${path}/sales-category/addNew`}
        component={AddSalesCategory}
      />
      <Route
        exact
        path={`${path}/map-store-product`}
        component={ProductToStoreMapping}
      />
      <Route
        exact
        path={`${path}/add-map-store`}
        component={AddMapStore}
      />
      <Route exact path={`${path}/cart`} component={DisplayCarts} />
      <Route exact path={`${path}/edit-cart`} component={CartOperations} />
      <Route exact path={`${path}/view-cart`} component={CartOperations} />
      <Route
        exact
        path={`${path}/default-configuration`}
        component={DisplayConfiguration}
      />
      <Route
        exact
        path={`${path}/default-configuration/addNew`}
        component={AddConfiguration}
      />
      {/* Campaign */}
      <Route exact path={`${path}/promotions`} component={DisplayPromotion} />
      <Route
        exact
        path={`${path}/promotions/addNew`}
        component={AddPromotion}
      />
      <Route
        exact
        path={`${path}/coupon-management`}
        component={DisplayCoupons}
      />
      <Route
        exact
        path={`${path}/coupon-management/addNew`}
        component={AddCoupon}
      />
      <Route
        exact
        path={`${path}/coupon-management/issue-coupon`}
        component={IssueCouponBatch}
      />
      {/* <Route
        exact
        path={`${path}/coupon-management/exportCoupon`}
        component={ExportCoupon}
      /> */}
      <Route
        exact
        path={`${path}/coupon-rules`}
        component={DisplayCouponRule}
      />
      <Route
        exact
        path={`${path}/coupon-rules/add`}
        component={AddCouponRule}
      />
      <Route
        exact
        path={`${path}/coupon-redemption-rules`}
        component={DisplayCouponRedemption}
      />
      <Route
        exact
        path={`${path}/coupon-redemption-rules/addNew`}
        component={AddCouponRedemptionRule}
      />
      <Route
        exact
        path={`${path}/coupon-inventory`}
        component={DisplayCouponInventory}
      />
      <Route
        exact
        path={`${path}/coupon-transaction`}
        component={DisplayCouponTransaction}
      />
      <Route
        exact
        path={`${path}/issue-export-coupon`}
        component={IssueAndExportCoupon}
      />
      <Route
        exact
        path={`${path}/point-rule/addNew`}
        component={AddPointRule}
      />
      <Route
        exact
        path={`${path}/point-rule`}
        component={DisplayPointRule}
      />
      <Route
        exact
        path={`${path}/rabbit-point-rule`}
        component={DisplayRabbitPointRule}
      />
      <Route
        exact
        path={`${path}/rabbit-point-rule/addNew`}
        component={AddRabbitPointRule}
      />
      {/* <Route
        exact
        path={`${path}/coupon-utilisation-report`}
        component={CouponUtilisationReport}
      /> */}
      <Route
        exact
        path={`${path}/survey-analytics`}
        component={SurveyAnalytics}
      />
      <Route
        exact
        path={`${path}/campaign-analysis`}
        component={CampaignAnalysis}
      />
      <Route
        exact
        path={`${path}/mobile-analytics`}
        component={MobileAnalysis}
      />
      <Route
        exact
        path={`${path}/member-analytics`}
        component={MemberAnalysis}
      />
      <Route
        exact
        path={`${path}/store-analytics`}
        component={StoreAnalytics}
      />
      {/* <Route
        exact
        path={`${path}/sales-analytics`}
        component={SalesAnalytics}
      /> */}
      <Route
        exact
        path={`${path}/sales-report`}
        component={SalesProductReport}
      />
      <Route
        exact
        path={`${path}/sales-tracker-report`}
        component={SalesTrackerReport}
      />
      <Route
        exact
        path={`${path}/sales-member-report`}
        component={MemberReport}
      />
      {/* Dummy */}
      <Route exact path={`${path}/questions`} component={DisplayQuestions} />
      <Route
        exact
        path={`${path}/questions/add-new`}
        component={AddQuestions}
      />
      <Route
        exact
        path={`${path}/survey-webform`}
        component={DisplayWebForm}
      />
      <Route
        exact
        path={`${path}/survey-webform/add-new`}
        component={AddWebform}
      />
      <Route exact path={`${path}/survey-report`} component={SurveyReport} />
      {/* To be deleted */}
      <Route exact path={`${path}/editor`} component={Editor} />
      <Route exact path={`${path}/Dnd`} component={DnD} />
      {/* Inventory Service */}
      <Route
        exact
        path={`${path}/add-map-inventory`}
        component={AddStoreInventory}
      />
      <Route exact path={`${path}/inventory`} component={InventoryService} />

      {/* Sales Promotions */}
      <Route
        exact
        path={`${path}/sales-promotions/addNew`}
        component={AddSalesPromotions}
      />
      <Route
        exact
        path={`${path}/sales-promotions-new/addNew`}
        component={AddSalesPromotionsNew}
      />
      <Route exact path={`${path}/sales-promotions`} component={DisplaySalesPromotions} />
      <Route exact path={`${path}/sales-promotions-new`} component={PromotionList} />

      <Route
        exact
        path={`${path}/sales-promotions-pgroups/addNew`}
        component={AddPromoPurchaseGroup}
      />
      <Route exact path={`${path}/sales-promotions-pgroups`} component={DisplayPromoPurchaseGroups} />

      {/* Sales Product Mapping */}
      <Route
        exact
        path={`${path}/sales-document/addNew`}
        component={AddSalesDocument}
      />
      <Route exact path={`${path}/sales-document`} component={DisplaySalesDocument} />

      <Route
        exact
        path={`${path}/users`}
        component={Users}
      />
      <Route
        exact
        path={`${path}/users/addNew`}
        component={AddUser}
      />

      <Route
        exact
        path={`${path}/usergroups`}
        component={UserGroups}
      />

      <Route
        exact
        path={`${path}/usergroups/addNew`}
        component={AddUserGroup}
      />

      <Route
        exact
        path={`${path}/userroles`}
        component={UserRoles}
      />

      <Route
        exact
        path={`${path}/userroles/addNew`}
        component={AddRole}
      /> 

      <Route exact path={`${path}/storegroups`} component={DisplayStoreGroups} />
      <Route exact path={`${path}/storegroups/addNew`} component={AddStoreGroup} />
      <Route exact path={`${path}/stores`} component={DisplayStores} />
      <Route exact path={`${path}/stores/addNew`} component={AddStore} />
      <Route exact path={`${path}/storeoptions`} component={StoreOptionList} />
      <Route exact path={`${path}/storeoptions/addNew`} component={AddStoreOptions} />
      <Route exact path={`${path}/storetenders`} component={DisplayStoreTenders} />
      <Route exact path={`${path}/storetenders/addNew`} component={AddStoreTenders} />
      <Route exact path={`${path}/terminals`} component={DisplayStoreTerminals} />
      <Route exact path={`${path}/terminals/addNew`} component={AddStoreTerminal} />
      <Route exact path={`${path}/terminaloptions`} component={TerminalOptionList} />
      <Route exact path={`${path}/terminaloptions/addNew`} component={AddTerminalOptions} />
      <Route exact path={`${path}/terminaltenders`} component={DisplayTerminalTenders} />
      <Route exact path={`${path}/terminaltenders/addNew`} component={AddTerminalTenders} />
      <Route exact path={`${path}/devicebrokers`} component={DisplayDeviceBrokers} />
      <Route exact path={`${path}/devicebrokers/addNew`} component={AddDeviceBroker} />
      <Route exact path={`${path}/posperipherals`} component={DisplayPosPeripherals} />
      <Route exact path={`${path}/posperipherals/addNew`} component={AddPosPeripheral} />
      <Route exact path={`${path}/apilogs`} component={ApiLog} />
      <Route exact path={`${path}/eip-messages`} component={EipMessage} />
      <Route exact path={`${path}/jobtasklogs`} component={JobTaskLog} />
      <Route exact path={`${path}/datasynclogs`} component={DataSyncLog} />

      {/* Task Assign Project */}
      <Route exact path={`${path}/customer-registration`} component={CustomerRegistration} />
      <Route exact path={`${path}/customer-registration/addNew`} component={AddCustomerRegistration} />
      <Route exact path={`${path}/technician-registration`} component={TechnicianRegistration} />
      <Route exact path={`${path}/technician-registration/addNew`} component={AddTechnicianRegistration} />
      <Route exact path={`${path}/technicianmanager-registration`} component={TechnicianManagerRegistration} />
      <Route exact path={`${path}/technicianmanager-registration/addNew`} component={AddTechnicianManagerRegistration} />
      <Route exact path={`${path}/item-registration`} component={ItemRegistration} />
      <Route exact path={`${path}/item-registration/addNew`} component={AddItemRegistration} />

      <Route exact path={`${path}/report/sales-analytics`} component={SalesAnalytics}/>
      <Route exact path={`${path}/report/pos-master`} component={PosMasterReport} />
      <Route exact path={`${path}/report/cart-sync`} component={CartSyncReport} />
      <Route exact path={`${path}/report/tender-listing`} component={TenderListingReport} />
      <Route exact path={`${path}/report/refund`} component={RefundReport} />
      <Route exact path={`${path}/report/rabbit-member`} component={RabbitMemberReport} />
      <Route exact path={`${path}/report/product-price`} component={ProductPriceDetailsReport} />
      <Route exact path={`${path}/report/sales`} component={SalesDetailsReport} />
      <Route exact path={`${path}/report/product-sales`} component={ProductSalesSummary} />
      <Route exact path={`${path}/report/ej`} component={EJ} />
      <Route exact path={`${path}/report/daily-sales`} component={DailySalesReport} />
      <Route exact path={`${path}/report/monthly-sales`} component={MonthlySalesReport} />
      <Route exact path={`${path}/report/term-sales-summary-report`} component={BIRTermSalesSummary} />
      <Route exact path={`${path}/report/special-discounts-report`} component={BIRSpecialDiscountReport} />
      <Route exact path={`${path}/report/data-validation`} component={DataValidation} />
      <Route exact path={`${path}/report/services`} component={ServicesReport} />
      <Route exact path={`${path}/report/sales-sync-exception-report`} component={SalesSyncExceptionReport} />
      <Route exact path={`${path}/report/coupon-transaction-report`} component={CouponTransactionReport} />
      <Route exact path={`${path}/report/special-discount-summary-report`} component={BIRSalesSummaryReport} />
      <Route exact path={`${path}/report/special-discount-type-report`} component={SpTerminalSummaryReport} />
      <Route exact path={`${path}/replenishment`} component={ReplenishmentList} />
      <Route exact path={`${path}/replenishment/create`} component={ReplenishmentDetail} />
      <Route exact path={`${path}/sales-return`} component={SalesReturnList} />
      <Route exact path={`${path}/sales-return/add`} component={SalesReturnDetail} />
      <Route exact path={`${path}/sales-invoice`} component={CollectionInvoiceList} />
      <Route exact path={`${path}/sales-invoice/detail`} component={CollectionInvoiceDetail} />
      <Route exact path={`${path}/extension/sale-stock-report`} component={SaleStockReport} />
      <Route exact path={`${path}/extension/file-integration-report`} component={FileIntegrationReport} />
      <Route exact path={`${path}/extension/sale-return-price`} component={SaleReturnPrice} />

    </Switch>
  ), [props.location.pathname])

  return _renderSwitch
}

MainRouterPage.propTypes = {};

const mapStateToProps = state => {
  return {
    queryData: state.queryData,
    crudData: state.crudData,
    loginData: state.loginData
  };
};

export default connect(mapStateToProps)(MainRouterPage);
