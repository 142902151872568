import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
    voidCart,
    suspendCart,
    closeCart,
    getByOrderId,
    retrieveDeviceInfo,
} from '../../../actions/actions';
import ConfirmAlert from '../../../components/ConfirmAlert';
import { produce } from 'immer';
import Loading from '../../../components/Loading';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

const itemHeaders = [
    {
        dataFieldId: 'idx',
        label: 'ID',
    },
    {
        dataFieldId: 'productcode',
        label: 'Product Code',
    },
    {
        dataFieldId: 'description_item',
        label: 'Description',
    },
    {
        dataFieldId: 'addons',
        label: 'Addons',
    },
    {
        dataFieldId: 'status',
        label: 'Status',
    },
    {
        dataFieldId: 'quantity',
        label: 'Quantity',
    },
    {
        dataFieldId: 'unitprice',
        label: 'Unit Price',
    },
    {
        dataFieldId: 'totalamount',
        label: 'Totalamount',
    },
    {
        dataFieldId: 'taxamount',
        label: 'Tax Amount',
    },
];

const paymentHeaders = [
    {
        dataFieldId: 'idx',
        label: 'ID',
    },
    {
        dataFieldId: 'description',
        label: 'Description',
    },
    {
        dataFieldId: 'payamount',
        label: 'Pay Amount',
    },
    {
        dataFieldId: 'paytype',
        label: 'Pay Type',
    },
    {
        dataFieldId: 'paytyperef',
        label: 'Paytype Ref/SourceId',
    },
    {
        dataFieldId: 'payment_lastupdated',
        label: 'Last Updated',
    },
    {
        dataFieldId: 'status',
        label: 'Status',
    },
    {
        dataFieldId: 'additionalfields.message',
        label: 'Message',
    },
];

const refundHeaders = [
    {
        dataFieldId: 'idx',
        label: 'ID',
    },
    {
        dataFieldId: 'description',
        label: 'Description',
    },
    {
        dataFieldId: 'refundamount',
        label: 'Refund Amount',
    },
    {
        dataFieldId: 'refundtype',
        label: 'Refund Type',
    },
    {
        dataFieldId: 'refundtyperef',
        label: 'Refund Ref',
    },
    {
        dataFieldId: 'status',
        label: 'Status',
    },
];

const taxHeaders = [
    {
        dataFieldId: 'idx',
        label: 'ID',
    },
    {
        dataFieldId: 'description',
        label: 'Description',
    },
    {
        dataFieldId: 'taxtype',
        label: 'Tax Type',
    },
    {
        dataFieldId: 'taxvalue',
        label: 'Tax Value',
    },
    {
        dataFieldId: 'taxamount',
        label: 'Tax Amount',
    },
    {
        dataFieldId: 'status',
        label: 'Status',
    },
    {
        dataFieldId: 'lastupdated',
        label: 'Last Updated',
    },
];

const discountHeaders = [
    {
        dataFieldId: 'idx',
        label: 'ID',
    },
];

const tagHeaders = [
    {
        dataFieldId: 'idx',
        label: 'ID',
    },
];

const extraHeaders = [
    {
        dataFieldId: 'idx',
        label: 'ID',
    },
    {
        dataFieldId: 'additionalfields',
        label: 'Description',
    },
    {
        dataFieldId: 'additionalfields.remark',
        label: 'Remarks',
    },
    {
        dataFieldId: 'extgroup',
        label: 'Group',
    },
    {
        dataFieldId: 'extamount',
        label: 'Amount',
    },
    {
        dataFieldId: 'status',
        label: 'Status',
    },
];

class CartOperations extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            orderid: '',
            deviceid: '',
            terminalid: '',
            storeid: '',
            storeref: '',
            sessionid: '',
            trxdate: '',
            memberid: '',
            loyaltyid: '',
            optaskqno: '',
            oprationmode: '',
            possessionid: '',
            posorderid: '',
            posorderstatus: '',
            postotalamount: 0,
            posorderref: '',
            status: 'New',
            subtotalamount: 0,
            totaltaxamount: 0,
            totaldiscountamount: 0,
            totalpaidamount: 0,
            salestype: '',
            staffid: '',
            cartid: '',
            formError: false,
            formErrorMsg: '',
            deviceInfo: {},
            openCloseprompt: false,
        };
    }

    componentWillMount = () => {
        const defLanguage = this.props.loginData.language;
        this.setState({ language: defLanguage });
        if (this.props.location && this.props.location.state) {
            const rec = this.props.location.state.item;

            if (rec) {
                const { orderid, cartid } = rec;
                this.setState(
                    produce((draft) => {
                        draft.orderid = orderid || '';
                        draft.cartid = cartid || '';
                    }),
                );
                this.props.dispatch(
                    getByOrderId(`pos/v1/cart/${cartid}/${orderid}`),
                );
            }
        }
    };

    UNSAFE_componentWillReceiveProps = (nextProps) => {
        const { cart, deviceInfo } = this.props.queryData;
        const { deviceInfo: nextDeviceInfo, cart: nextCart } =
            nextProps.queryData;

        if (nextDeviceInfo !== deviceInfo) {
            this.setState(
                produce((draft) => {
                    draft.deviceInfo = nextDeviceInfo;
                }),
            );
        }

        if (
            nextCart.deviceid &&
            nextCart.deviceid !== '' &&
            nextCart !== cart
        ) {
            this.props.dispatch(retrieveDeviceInfo(nextCart.deviceid));
        }
    };

    _voidcart = () => {
        const { orderid } = this.state;
        this.props.dispatch(voidCart({ body: { orderid: orderid } }, orderid));
    };
    _suspendcart = () => {
        const { orderid } = this.state;
        this.props.dispatch(
            suspendCart({ body: { orderid: orderid } }, orderid),
        );
    };
    _reqClosecart = () => {
        this.setState({ openCloseprompt: true });
    };
    _closecart = (shouldClose) => {
        this.setState({ openCloseprompt: false });
        if (shouldClose) {
            const { orderid } = this.state;
            this.props.dispatch(
                closeCart({ body: { orderid: orderid } }, orderid),
            );
        }
    };
    _renderErrorMessage = () => {
        const { isSuccess, errormsg } = this.props.crudData;
        const { formError, formErrorMsg } = this.state;
        if (errormsg && !isSuccess) {
            return (
                <div className="alert alert-danger" role="alert">
                    {errormsg}
                </div>
            );
        } else if (formError && formErrorMsg) {
            return (
                <div className="alert alert-danger" role="alert">
                    {formErrorMsg}
                </div>
            );
        }
        return;
    };

    _canCloseCart = () => {
        const { cart } = this.props.queryData;
        return cart.status === 'sales' && cart.totalpaidamount !== 0;
    };

    _isViewMode = () => {
        return (
            this.props.location &&
            this.props.location.state &&
            this.props.location.state.isView
        );
    };

    _renderMainButton = () => {
        const fields = this.props.location.state.obj;
        if (this._isViewMode) {
            return (
                <>
                    <Link
                        to={{
                            pathname: '/ui/cart',
                            state: {
                                fields,
                            },
                        }}
                        className="btn btn-themes btn-rounded btn-sec link-sec-btn"
                    >
                        Cancel
                    </Link>
                </>
            );
        }

        return (
            <>
                <button
                    type="submit"
                    disabled
                    className="btn btn-themes btn-rounded"
                    onClick={() => this._voidcart()}
                >
                    Void Cart
                </button>

                <button
                    type="submit"
                    disabled
                    className="btn btn-themes btn-rounded"
                    onClick={() => this._suspendcart()}
                >
                    Suspend Cart
                </button>
                <button
                    type="submit"
                    disabled={!this._canCloseCart()}
                    className="btn btn-themes btn-rounded"
                    onClick={() => this._reqClosecart()}
                >
                    Close Cart
                </button>
                <Link
                    to={{
                        pathname: '/ui/cart',
                        state: {
                            fields,
                        },
                    }}
                    className="btn btn-themes btn-rounded btn-sec link-sec-btn"
                >
                    Cancel
                </Link>
            </>
        );
    };

    _renderFormArea = () => {
        return (
            <>
                <div id="salesorder" style={{ backgroundColor: '#fff' }}>
                    <div className="row sub-title1 noPadding noMargin cartMainRow">
                        Order Information
                    </div>

                    <div className="title_devide"></div>
                    <div className="splitFrmDiv1Sec text-center">
                        <div className="row">
                            <div className="col-md-5 col-sm-12 noPadding noMargin">
                                {this._renderBasicInformation()}
                            </div>
                            <div className="col-md-1 col-sm-12 noPadding noMargin"></div>
                            <div className="col-md-5 col-sm-12 noPadding noMargin">
                                {this._renderBasicInformation_rest()}
                            </div>
                        </div>
                    </div>
                    {this._renderGiftDetails()}
                    <div className="row rowBottom">
                        <div className="col-md-12 col-sm-12 noPadding noMargin">
                            <div
                                className="splitFrmDiv1Sec text-center"
                                style={{ marginTop: '2em' }}
                            >
                                {this._renderOrderDetails()}
                            </div>
                        </div>
                    </div>
                    <div className="row rowBottom">
                        <div className="col-md-12 col-sm-12 noPadding noMargin">
                            <div
                                className="splitFrmDiv1Sec text-center"
                                style={{ marginTop: '2em' }}
                            >
                                {this._renderPaymentInformation()}
                            </div>
                        </div>
                    </div>
                    <div className="row rowBottom">
                        <div className="col-md-12 col-sm-12 noPadding noMargin">
                            <div
                                className="splitFrmDiv1Sec text-center"
                                style={{ marginTop: '2em' }}
                            >
                                {this._renderRefundInformation()}
                            </div>
                        </div>
                    </div>
                    <div className="row rowBottom">
                        <div className="col-md-12 col-sm-12 noPadding noMargin">
                            <div
                                className="splitFrmDiv1Sec text-center"
                                style={{ marginTop: '2em' }}
                            >
                                {this._renderTaxInformation()}
                            </div>
                        </div>
                    </div>
                    <div className="row rowBottom">
                        <div className="col-md-12 col-sm-12 noPadding noMargin">
                            <form
                                className="splitFrmDiv1Sec text-center"
                                style={{ marginTop: '2em' }}
                            >
                                {this._renderTagInformation()}
                            </form>
                        </div>
                    </div>
                    <div className="row rowBottom">
                        <div className="col-md-12 col-sm-12 noPadding noMargin">
                            <form
                                className="splitFrmDiv1Sec text-center"
                                style={{ marginTop: '2em' }}
                            >
                                {this._renderDiscountInformation()}
                            </form>
                        </div>
                    </div>
                    <div className="row rowBottom">
                        <div className="col-md-12 col-sm-12 noPadding noMargin">
                            <form
                                className="splitFrmDiv1Sec text-center"
                                style={{ marginTop: '2em' }}
                            >
                                {this._renderExtraInformation()}
                            </form>
                        </div>
                    </div>
                    <div style={{ height: '20px' }}></div>
                </div>
            </>
        );
    };
    _renderBasicInformation = () => {
        const { cart } = this.props.queryData;
        return (
            <>
                <div
                    className="form-group row cartRow"
                    style={{ width: '100%' }}
                >
                    <label className="col-sm-12 col-md-5 col-form-label alignLeft mini_title">
                        Order ID
                    </label>
                    <div className="col-sm-12 col-md-7">
                        <p className="text-left textclass">
                            {this.state.orderid}
                        </p>
                    </div>
                </div>
                <div className="title_devide"></div>
                <div className="form-group row cartRow">
                    <label className="col-sm-12 col-md-5 col-form-label alignLeft mini_title">
                        Store
                    </label>
                    <div className="col-sm-12 col-md-7">
                        <p className="text-left textclass">{cart.storeid}</p>
                    </div>
                </div>
                <div className="title_devide"></div>
                <div className="form-group row cartRow">
                    <label className="col-sm-12 col-md-5 col-form-label alignLeft mini_title">
                        Sales Type
                    </label>
                    <div className="col-sm-12 col-md-7">
                        <p className="text-left textclass">{cart.salestype}</p>
                    </div>
                </div>
                <div className="title_devide"></div>
                <div className="form-group row cartRow">
                    <label className="col-sm-12 col-md-5 col-form-label alignLeft mini_title">
                        Transaction Date
                    </label>
                    <div className="col-sm-12 col-md-7">
                        <p className="text-left textclass">{cart.trxdate}</p>
                    </div>
                </div>
                <div className="title_devide"></div>
                <div className="form-group row cartRow">
                    <label className="col-sm-12 col-md-5 col-form-label alignLeft mini_title">
                        POS Total Amount
                    </label>
                    <div className="col-sm-12 col-md-7">
                        <p className="text-left textclass">
                            {cart.postotalamount}
                        </p>
                    </div>
                </div>
                <div className="title_devide"></div>
                <div className="form-group row cartRow">
                    <label className="col-sm-12 col-md-5 col-form-label alignLeft mini_title">
                        Sub Total Amount
                    </label>
                    <div className="col-sm-12 col-md-7">
                        <p className="text-left textclass">
                            {cart.subtotalamount}
                        </p>
                    </div>
                </div>
                <div className="title_devide"></div>
                <div className="form-group row cartRow">
                    <label className="col-sm-12 col-md-5 col-form-label alignLeft mini_title">
                        Total Discount Amount
                    </label>
                    <div className="col-sm-12 col-md-7">
                        <p className="text-left textclass">
                            {cart.totaldiscountamount}
                        </p>
                    </div>
                </div>
                <div className="title_devide"></div>
                <div className="form-group row cartRow">
                    <label className="col-sm-12 col-md-5 col-form-label alignLeft mini_title">
                        Total Paid Amount
                    </label>
                    <div className="col-sm-12 col-md-7">
                        <p className="text-left textclass">
                            {cart.totalpaidamount}
                        </p>
                    </div>
                </div>
                <div className="title_devide"></div>
                <div className="form-group row cartRow">
                    <label className="col-sm-12 col-md-5 col-form-label alignLeft mini_title">
                        Total Change Amount
                    </label>
                    <div className="col-sm-12 col-md-7">
                        <p className="text-left textclass">
                            {cart.totalchangeamount}
                        </p>
                    </div>
                </div>
                <div className="title_devide"></div>
                <div className="form-group row cartRow">
                    <label className="col-sm-12 col-md-5 col-form-label alignLeft mini_title">
                        Total Tax Amount
                    </label>
                    <div className="col-sm-12 col-md-7">
                        <p className="text-left textclass">
                            {cart.totaltaxamount}
                        </p>
                    </div>
                </div>
                <div className="title_devide"></div>
                <div className="form-group row cartRow">
                    <label className="col-sm-12 col-md-5 col-form-label alignLeft mini_title">
                        Member ID
                    </label>
                    <div className="col-sm-12 col-md-7">
                        <p className="text-left textclass">{cart.memberid}</p>
                    </div>
                </div>
                <div className="title_devide"></div>
                <div className="form-group row cartRow">
                    <label className="col-sm-12 col-md-5 col-form-label alignLeft mini_title">
                        Loyalty ID
                    </label>
                    <div className="col-sm-12 col-md-7">
                        <p className="text-left textclass">{cart.loyaltyid}</p>
                    </div>
                </div>
                <div className="title_devide"></div>
                <div className="form-group row cartRow">
                    <label className="col-sm-12 col-md-5 col-form-label alignLeft mini_title">
                        Is Gift
                    </label>
                    <div className="col-sm-12 col-md-7">
                        <p className="text-left textclass">
                            {this._hasGift(cart.extras) ? 'Y' : 'N'}
                        </p>
                    </div>
                </div>
                <div className="title_devide"></div>
                <div className="form-group row cartRow">
                    <label className="col-sm-12 col-md-5 col-form-label alignLeft mini_title">
                        Bulky Item Flag
                    </label>
                    <div className="col-sm-12 col-md-7">
                        <p className="text-left textclass">
                            {cart.additionalfields.deliverybulkyitem}
                        </p>
                    </div>
                </div>
                <div className="title_devide"></div>
                <div className="form-group row cartRow">
                    <label className="col-sm-12 col-md-5 col-form-label alignLeft mini_title">
                        Order Before
                    </label>
                    <div className="col-sm-12 col-md-7">
                        <p className="text-left textclass">
                            {cart.additionalfields.orderbefore}
                        </p>
                    </div>
                </div>
            </>
        );
    };

    _renderBasicInformation_rest = () => {
        const { cart } = this.props.queryData;
        return (
            <>
                <div
                    className="form-group row cartRow"
                    style={{ width: '100%' }}
                >
                    <label className="col-sm-12 col-md-5 col-form-label alignLeft mini_title">
                        Store Ref
                    </label>
                    <div className="col-sm-12 col-md-7">
                        <p className="text-left textclass">{cart.storeref}</p>
                    </div>
                </div>
                <div className="title_devide"></div>
                <div className="form-group row cartRow">
                    <label className="col-sm-12 col-md-5 col-form-label alignLeft mini_title">
                        Order Status
                    </label>
                    <div className="col-sm-12 col-md-7">
                        <p className="text-left textclass">{cart.status}</p>
                    </div>
                </div>
                <div className="title_devide"></div>

                <div className="form-group row cartRow">
                    <label className="col-sm-12 col-md-5 col-form-label alignLeft mini_title">
                        Device ID
                    </label>
                    <div className="col-sm-12 col-md-7">
                        <p className="text-left textclass">
                            {cart.deviceid} [{this.state.deviceInfo.devicetype}]
                        </p>
                    </div>
                </div>
                <div className="title_devide"></div>
                <div className="form-group row cartRow">
                    <label className="col-sm-12 col-md-5 col-form-label alignLeft mini_title">
                        Task Queue Number
                    </label>
                    <div className="col-sm-12 col-md-7">
                        <p className="text-left textclass">{cart.optaskqno}</p>
                    </div>
                </div>
                <div className="title_devide"></div>
                <div className="form-group row cartRow">
                    <label className="col-sm-12 col-md-5 col-form-label alignLeft mini_title">
                        Operation Mode
                    </label>
                    <div className="col-sm-12 col-md-7">
                        <p className="text-left textclass">
                            {cart.oprationmode}
                        </p>
                    </div>
                </div>
                <div className="title_devide"></div>
                <div className="form-group row cartRow">
                    <label className="col-sm-12 col-md-5 col-form-label alignLeft mini_title">
                        POS Order ID
                    </label>
                    <div className="col-sm-12 col-md-7">
                        <p className="text-left textclass">{cart.posorderid}</p>
                    </div>
                </div>
                <div className="title_devide"></div>
                <div className="form-group row cartRow">
                    <label className="col-sm-12 col-md-5 col-form-label alignLeft mini_title">
                        POS Order Ref
                    </label>
                    <div className="col-sm-12 col-md-7">
                        <p className="text-left textclass">
                            {cart.posorderref}
                        </p>
                    </div>
                </div>
                <div className="title_devide"></div>
                <div className="form-group row cartRow">
                    <label className="col-sm-12 col-md-5 col-form-label alignLeft mini_title">
                        POS Order Status
                    </label>
                    <div className="col-sm-12 col-md-7">
                        <p className="text-left textclass">
                            {cart.posorderstatus}
                        </p>
                    </div>
                </div>
                <div className="title_devide"></div>
                <div className="form-group row cartRow">
                    <label className="col-sm-12 col-md-5 col-form-label alignLeft mini_title">
                        POS Session ID
                    </label>
                    <div className="col-sm-12 col-md-7">
                        <p className="text-left textclass">
                            {cart.possessionid}
                        </p>
                    </div>
                </div>

                <div className="title_devide"></div>
                <div className="form-group row cartRow">
                    <label className="col-sm-12 col-md-5 col-form-label alignLeft mini_title">
                        Session ID
                    </label>
                    <div className="col-sm-12 col-md-7">
                        <p className="text-left textclass">{cart.sessionid}</p>
                    </div>
                </div>
                <div className="title_devide"></div>
                <div className="form-group row cartRow">
                    <label className="col-sm-12 col-md-5 col-form-label alignLeft mini_title">
                        Staff Id
                    </label>
                    <div className="col-sm-12 col-md-7">
                        <p className="text-left textclass">{cart.staffid}</p>
                    </div>
                </div>
                <div className="title_devide"></div>
                <div className="form-group row cartRow">
                    <label className="col-sm-12 col-md-5 col-form-label alignLeft mini_title">
                        Terminal ID
                    </label>
                    <div className="col-sm-12 col-md-7">
                        <p className="text-left textclass">{cart.terminalid}</p>
                    </div>
                </div>
                <div className="title_devide"></div>
                <div className="form-group row cartRow">
                    <label className="col-sm-12 col-md-5 col-form-label alignLeft mini_title">
                        Transaction No
                    </label>
                    <div className="col-sm-12 col-md-7">
                        <p className="text-left textclass">{cart.trxno}</p>
                    </div>
                </div>
                <div className="title_devide"></div>
                <div className="form-group row cartRow">
                    <label className="col-sm-12 col-md-5 col-form-label alignLeft mini_title">
                        Invoice No
                    </label>
                    <div className="col-sm-12 col-md-7">
                        <p className="text-left textclass">{cart.invoiceno}</p>
                    </div>
                </div>
                <div className="title_devide"></div>
            </>
        );
    };
    _renderTableHeaders = (headers) => {
        return (
            <thead>
                <tr>
                    {headers.map((column, index) => {
                        return (
                            <th key={`tableview-th-${index}`}>
                                {column.label}
                            </th>
                        );
                    })}
                </tr>
            </thead>
        );
    };
    _renderTableRows = (rows, headers) => {
        if (!rows) {
            return;
        }
        if (rows.length === 0) {
            return (
                <tr style={{ width: '100%' }}>
                    <td colSpan={6} style={{ textAlign: 'center' }}>
                        No data found
                    </td>
                </tr>
            );
        }
        return rows.map((row, index) => {
            return (
                <tr key={`cart-table-${0}-${index}`}>
                    {headers.map((column, index) => {
                        const col = column.dataFieldId.replace(/\s/g, '');
                        return col == 'addons' ? (
                            <td key={`cart-table-${rows}-${index}-addon`}>
                                {row.addons
                                    .filter((e) => e.quantity !== 0)
                                    .map((e) => (
                                        <div
                                            key={`cart-table-${rows}-${index}-${e.description}`}
                                        >
                                            {e.description}
                                        </div>
                                    ))}
                            </td>
                        ) : col == 'description_item' ? (
                            <td key={`cart-table-${1}-${index}-addon`}>
                                <div>{row.description}</div>
                                <div>
                                    {row?.additionalfields?.sales_uom?.trim()
                                        ? row.additionalfields.sales_uom +
                                          ' * ' +
                                          row.additionalfields.sales_qty +
                                          ' ' +
                                          'uom: ' +
                                          row.additionalfields.uom
                                        : ''}
                                    {row.itemsize.length == 0
                                        ? ''
                                        : 'Itemsize:' + row.itemsize}
                                </div>
                                <div>
                                    {row.itemcolor == 0
                                        ? ''
                                        : 'Itemcolor:' + row.itemcolor}
                                </div>
                            </td>
                        ) : col === 'payment_lastupdated' ? (
                            <td key={`cart-tableview-td-${index}-${index}`}>
                                {(row['lastupdated'] &&
                                    row['lastupdated'].length) > 9
                                    ? row['lastupdated'].substring(0, 10)
                                    : ''}
                            </td>
                        ) : col === 'payment_lastupdated' ? (
                            <td key={`cart-tableview-td-${index}-${index}`}>
                                {(row['lastupdated'] &&
                                    row['lastupdated'].length) > 9
                                    ? row['lastupdated'].substring(0, 10)
                                    : ''}
                            </td>
                        ) : col === 'paytyperef' ? (
                            <td key={`cart-tableview-td-${index}-${index}`}>
                                {typeof row.additionalfields !== 'undefined' &&
                                typeof row.additionalfields.sourceid !==
                                    'undefined'
                                    ? row.additionalfields.sourceid
                                    : row.paytyperef}
                            </td>
                        ) : col.indexOf('.') > 0 ? (
                            row[col.split('.')[0]] ? (
                                <td key={`cart-tableview-td-${col}-${index}`}>
                                    {row[col.split('.')[0]][col.split('.')[1]]}
                                </td>
                            ) : (
                                <td></td>
                            )
                        ) : (
                            <td key={`cart-tableview-td-${index}-${index}`}>
                                {row[col]}
                            </td>
                        );
                    })}
                </tr>
            );
        });
    };

    _renderExtraTableRows = (rows, headers) => {
        if (rows.length === 0) {
            return (
                <tr style={{ width: '100%' }}>
                    <td colSpan={6} style={{ textAlign: 'center' }}>
                        No data found
                    </td>
                </tr>
            );
        }
        return rows.map((row, index) => {
            return (
                <tr key={`extra-tableview-td-0-${index}`}>
                    {headers.map((column, index) => {
                        const col = column.dataFieldId;
                        const desc = Object.keys(row.additionalfields).reduce(
                            (arr, key) => {
                                const subObj = {
                                    [key]: row.additionalfields[key],
                                };
                                return arr.concat(subObj);
                            },
                            [],
                        );
                        return col === 'additionalfields' ? (
                            <td key={`extra-tableview-td-${index}`}>
                                {row['description']}
                                <div>
                                    Address:{' '}
                                    {desc.map((e) => (
                                        <div>
                                            {e.address}
                                            {e.city}
                                            {e.postalcode}
                                        </div>
                                    ))}
                                </div>
                            </td>
                        ) : col.indexOf('.') > 0 ? (
                            row[col.split('.')[0]] ? (
                                <td key={`extra-tableview-td-${col}-${index}`}>
                                    {row[col.split('.')[0]][col.split('.')[1]]}
                                </td>
                            ) : (
                                <td></td>
                            )
                        ) : (
                            <td key={`extra-tableview-td-${index}-${index}`}>
                                {row[col]}
                            </td>
                        );
                    })}
                </tr>
            );
        });
    };

    _hasGift = (extras) => {
        if (extras && extras[0]) {
            const lastIdx = extras.length - 1;
            return (
                extras[lastIdx] &&
                extras[lastIdx].additionalfields &&
                extras[lastIdx].additionalfields.gift === 'Y'
            );
        }
        return false;
    };

    _renderGiftDetails = () => {
        const { cart } = this.props.queryData;
        if (cart.extras && cart.extras[0]) {
            const lastIdx = cart.extras.length - 1;
            if (
                cart.extras[lastIdx] &&
                cart.extras[lastIdx].additionalfields &&
                cart.extras[lastIdx].additionalfields.giftdetails &&
                cart.extras[lastIdx].additionalfields.gift === 'Y'
            ) {
                const { giftdetails } = cart.extras[lastIdx].additionalfields;
                return (
                    <div className="row rowBottom">
                        <div className="col-md-12 col-sm-12 noPadding noMargin">
                            <div
                                className="splitFrmDiv1Sec text-center"
                                style={{ marginTop: '2em' }}
                            >
                                <div className="row sub-title1 noPadding noMargin">
                                    Gift Details
                                </div>
                                <div className="title_devide"></div>
                                <div className="form-group row">
                                    <label className="col-md-3 col-form-label alignLeft mini_title">
                                        Gift
                                    </label>
                                    <div className="col-md-9 ">
                                        <p className="text-left textclass">
                                            {giftdetails.isgift}
                                        </p>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-md-3 col-form-label alignLeft mini_title">
                                        Receipient
                                    </label>
                                    <div className="col-md-9">
                                        <p className="text-left textclass">
                                            {giftdetails.recipient}
                                        </p>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-md-3 col-form-label alignLeft mini_title">
                                        Sender
                                    </label>
                                    <div className="col-md-9">
                                        <p className="text-left textclass">
                                            {giftdetails.sender}
                                        </p>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-sm-3 col-form-label alignLeft mini_title">
                                        Notes
                                    </label>
                                    <div className="col-sm-9">
                                        <pre className="text-left textclass">
                                            {giftdetails.notestorecipient}
                                        </pre>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }
        }

        return <></>;
    };

    _renderOrderDetails = () => {
        const { cart } = this.props.queryData;
        if (!cart?.items) return null;  // Return null if cart or cart.items is undefined
    
        console.log('items::', cart.items);
    
        return (
            <>
                <div className="row sub-title1 noPadding noMargin">
                    Item Details
                </div>
                <div className="title_devide"></div>
    
                <table className="table table-bordered">
                    <thead className="thead-green">
                        <tr>
                            {itemHeaders.map((column, index) => (
                                <th key={`tableview-th-${index}`}>
                                    {column.label}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {this._renderTableRows(cart.items, itemHeaders)}
                    </tbody>
                </table>
            </>
        );
    };
    

    _renderPaymentInformation = () => {
        const { cart } = this.props.queryData;

        return (
            <>
                <div className="row sub-title1 ">Payment Details</div>
                <div className="title_devide"></div>

                <table className="table table-bordered">
                    <thead className="thead-green">
                        <tr>
                            {paymentHeaders.map((column, index) => {
                                return (
                                    <th key={`tableview-th-${index}`}>
                                        {column.label}
                                    </th>
                                );
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {this._renderTableRows(cart.payments, paymentHeaders)}
                    </tbody>
                </table>
            </>
        );
    };

    _renderRefundInformation = () => {
        const { cart } = this.props.queryData;

        return (
            <>
                <div className="row sub-title1 ">Refund Details</div>
                <div className="title_devide"></div>

                <table className="table table-bordered">
                    <thead className="thead-green">
                        <tr>
                            {refundHeaders.map((column, index) => {
                                return (
                                    <th key={`tableview-th-${index}`}>
                                        {column.label}
                                    </th>
                                );
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {this._renderTableRows(cart.refunds, refundHeaders)}
                    </tbody>
                </table>
            </>
        );
    };

    _renderDiscountInformation = () => {
        const { cart } = this.props.queryData;

        return (
            <>
                <div className="row sub-title1 ">Discount Details</div>
                <div className="title_devide"></div>

                <table className="table table-bordered">
                    <thead className="thead-green">
                        <tr>
                            {refundHeaders.map((column, index) => {
                                return (
                                    <th key={`tableview-th-${index}`}>
                                        {column.label}
                                    </th>
                                );
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {this._renderTableRows(cart.refunds, refundHeaders)}
                    </tbody>
                </table>
            </>
        );
    };

    _renderTaxInformation = () => {
        const { cart } = this.props.queryData;
        return (
            <>
                <div className="row sub-title1 noPadding noMargin">
                    Tax Details
                </div>
                <div className="title_devide"></div>

                <table className="table table-bordered">
                    <thead className="thead-green">
                        <tr>
                            {taxHeaders.map((column, index) => {
                                return (
                                    <th key={`tableview-th-${index}`}>
                                        {column.label}
                                    </th>
                                );
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {this._renderTableRows(cart.taxes, taxHeaders)}
                    </tbody>
                </table>
            </>
        );
    };

    _renderDiscountInformation = () => {
        const { cart } = this.props.queryData;
        return (
            <>
                <div className="row sub-title1 noPadding noMargin">
                    Sales Discount
                </div>
                <div className="title_devide"></div>
                <table className="table table-bordered">
                    <thead className="thead-green">
                        <tr>
                            {discountHeaders.map((column, index) => {
                                return (
                                    <th key={`tableview-th-${index}`}>
                                        {column.label}
                                    </th>
                                );
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {this._renderTableRows(cart.discounts, discountHeaders)}
                    </tbody>
                </table>
            </>
        );
    };

    _renderTagInformation = () => {
        const { cart } = this.props.queryData;
        return (
            <>
                <div className="row sub-title1 noPadding noMargin">
                    Sales Tag
                </div>
                <div className="title_devide"></div>
                <table className="table table-bordered">
                    <thead className="thead-green">
                        <tr>
                            {tagHeaders.map((column, index) => {
                                return (
                                    <th key={`tableview-th-${index}`}>
                                        {column.label}
                                    </th>
                                );
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {this._renderTableRows(cart.tags, tagHeaders)}
                    </tbody>
                </table>
            </>
        );
    };

    _renderExtraInformation = () => {
        const { cart } = this.props.queryData;
        return (
            <>
                <div className="row sub-title1 noPadding noMargin">
                    Sales Extra
                </div>
                <div className="title_devide"></div>
                <table className="table table-bordered">
                    <thead className="thead-green">
                        <tr>
                            {extraHeaders.map((column, index) => {
                                return (
                                    <th key={`tableview-th-${index}`}>
                                        {column.label}
                                    </th>
                                );
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {this._renderExtraTableRows(cart.extras, extraHeaders)}
                    </tbody>
                </table>
            </>
        );
    };

    converter = () => {
        html2canvas(document.querySelector('#salesorder')).then((canvas) => {
            var imgData = canvas.toDataURL('image/png');
            var pdf = new jsPDF('p', 'pt', 'a4');
            var pageWidth = pdf.internal.pageSize.getWidth();
            var pageHeight = pdf.internal.pageSize.getHeight();
            let pdfwidth = pageWidth + 30;
            let pdfheight = pageHeight - 30;
            pdf.setFillColor('white');
            pdf.addImage(imgData, 'PNG', 0, 0, pdfwidth, pdfheight);
            pdf.save('salesorder.pdf');
        });
    };

    render() {
        const { isSuccess } = this.props.crudData;
        const { cart } = this.props.queryData;

        if (!cart || cart.length === 0) {
            return <Loading></Loading>;
        }
        return (
            <>
                <div className="form_height">
                    <div className="row">
                        <div className="col-md-10">
                            <h2 className="page-header">Order Details</h2>
                        </div>
                        <div className="col-md-2 floatRight">
                            <button
                                className="exportSearchSelect"
                                onClick={this.converter}
                            >
                                Download PDF
                            </button>
                        </div>
                    </div>
                    <div className="row form-container">
                        <div className="col">
                            <div className="frmDiv text-center">
                                {this._renderErrorMessage()}
                                {this._renderFormArea()}
                            </div>
                            <div className="row btn-container form-button">
                                <div className="col-12">
                                    {this._renderMainButton()}
                                </div>
                            </div>
                        </div>
                    </div>
                    <ConfirmAlert
                        show={this.state.openCloseprompt}
                        isPrompt={true}
                        children={
                            <div className="deleteAlertDiv">
                                Are you sure you want to Close this cart?
                                <div style={{ margin: '2em' }}>
                                    <button
                                        className="btn btn-themes btn-rounded btn-sec deletBtn"
                                        onClick={() => this._closecart(false)}
                                    >
                                        No
                                    </button>
                                    <button
                                        className="btn btn-themes  btn-rounded deletBtn"
                                        onClick={() => this._closecart(true)}
                                    >
                                        Yes
                                    </button>
                                </div>
                            </div>
                        }
                    />
                    <ConfirmAlert
                        show={isSuccess}
                        handleClose={this.hideModal}
                        to="/ui/cart"
                        children={
                            <div style={{ padding: '2em', color: 'green' }}>
                                Cart Updated Successfully
                            </div>
                        }
                    />
                </div>
            </>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        queryData: state.queryData,
        crudData: state.crudData,
        loginData: state.loginData,
    };
};

export default connect(mapStateToProps)(CartOperations);
