import React from 'react';
import {
    Document,
    Page,
    Text,
    View,
    StyleSheet,
    PDFViewer,
} from '@react-pdf/renderer';

const styles = StyleSheet.create({
    pdfTable: {
        width: '100%',
    },
    tableHeader: {
        fontWeight: '700',
        fontSize: '16px',
        textAlign: 'center',
        borderBottom: '1px solid #dee2e6',
        color: '#000',
        padding: 8,
    },
    title: {
        height: 29,
        textAlign: 'center',
        fontSize: 16,
        fontWeight: 'bold',
        padding: 12,
        color: '#000',
    },
    tCellRow: {
        display: 'flex',
        flexDirection: 'row',
        borderBottom: '1px solid #dee2e6',
    },
    tCell: {
        flex: 1,
        textAlign: 'center',
        padding: '16px 4px 8px 4px',
        fontSize: '10px',
        borderRight: '1px solid #dee2e6',
    },
    t2Cell: {
        flex: 2,
        textAlign: 'center',
        // alignItems: 'center',
        // justifyContent: 'center',
        fontSize: '10px',
        padding: '16px 4px 8px 4px',
        borderRight: '1px solid #dee2e6',
    },
    tDataCell: {
        flex: 1,
        textAlign: 'center',
        padding: 4,
        fontSize: '10px',
        borderRight: '1px solid #dee2e6',
    },
    t2DataCell: {
        flex: 2,
        textAlign: 'center',
        padding: 4,
        fontSize: '10px',
        borderRight: '1px solid #dee2e6',
    },
    mb4: {
        marginBottom: '4px',
    },
});

const tableHeaders = [
    'trxgroup',
    'trx type',
    'trxno',
    'invoice no',
    'invoice reset no',
    'reset no',
    'void no',
    'retuno',
    'storeid',
    'user code',
    'terminalid',
    'trxdate',
    'amount',
    'trxdid',
    'trxdtrx type',
    'trxd trxtype value',
    'trxd amount',
    'trxd tax',
    'trxdqty',
];

const addSpaceEveryNCharacters = (inputString, n) => {
    const regex = new RegExp(`(.{1,${n}})`, 'g');
    return inputString.replace(regex, '$1 ');
}

const getColumnStyle = (item) => {
    const TwoColumnArr = ['trxdate', 'trxgroup']
    if (TwoColumnArr.includes(item)) {
        return {...styles.t2Cell}
    }
    return { ...styles.tCell }
}

const MasterReportPdf = (props) => {
    const { data } = props;
    let filterData = data.filter(d => d.id !== null)
    return (
        <PDFViewer width="100%" height="600">
            <Document>
                <Page size="A4" style={styles.page} orientation="landscape">
                    <View style={styles.pdfTable}>
                        <View style={styles.tCellRow}>
                            {tableHeaders.map((item, index) => (
                                <View key={index} style={
                                    getColumnStyle(item)
                                }>
                                    <Text>{item}</Text>
                                </View>
                            ))}
                            
                        </View>
                        {filterData.reverse().map((item, index) => (
                            <View key={index} style={{ ...styles.tCellRow, ...styles.f10 }}>
                                
                                <View style={{ 
                                    ...styles.t2DataCell
                                }}>
                                    <Text>{item.trxgroup}</Text>
                                </View>
                                <View style={{ 
                                    ...styles.tDataCell
                                }}>
                                    <Text>{item.trxtype}</Text>
                                </View>
                                <View style={{ 
                                    ...styles.tDataCell
                                }}>
                                    <Text>{item.trxno}</Text>
                                </View>
                                <View style={{ 
                                    ...styles.tDataCell
                                }}>
                                    <Text>{item.invoiceno}</Text>
                                </View>
                                <View style={{ 
                                    ...styles.tDataCell
                                }}>
                                    <Text>{item.invoiceresetno}</Text>
                                </View>
                                <View style={{ 
                                    ...styles.tDataCell
                                }}>
                                    <Text>{item.resetno}</Text>
                                </View>
                                <View style={{ 
                                    ...styles.tDataCell
                                }}>
                                    <Text>{item.voidno}</Text>
                                </View>
                                <View style={{ 
                                    ...styles.tDataCell
                                }}>
                                    <Text>{item.retuno}</Text>
                                </View>
                                <View style={{ 
                                    ...styles.tDataCell
                                }}>
                                    <Text>{item.storeid}</Text>
                                </View>
                                <View style={{ 
                                    ...styles.tDataCell
                                }}>
                                    <Text>{item.usercode}</Text>
                                </View>
                                <View style={{ 
                                    ...styles.tDataCell
                                }}>
                                    <Text>{item.terminalid}</Text>
                                </View>
                                <View style={{ 
                                    ...styles.t2DataCell
                                }}>
                                    <Text>{item.trxdate}</Text>
                                </View>
                                <View style={{ 
                                    ...styles.tDataCell
                                }}>
                                    <Text>{item.amount}</Text>
                                </View>
                                <View style={{ 
                                    ...styles.tDataCell
                                }}>
                                    <Text>{item.trxdid}</Text>
                                </View>
                                <View style={{ 
                                    ...styles.tDataCell
                                }}>
                                    <Text>{item.trxdtrxtype ? addSpaceEveryNCharacters(item.trxdtrxtype, 5) : item.trxdtrxtype}</Text>
                                </View>
                                <View style={{ 
                                    ...styles.tDataCell
                                }}>
                                    <Text>{item.trxdtrxtypevalue}</Text>
                                </View>
                                <View style={{ 
                                    ...styles.tDataCell
                                }}>
                                    <Text>{item.trxdamount}</Text>
                                </View>
                                <View style={{ 
                                    ...styles.tDataCell
                                }}>
                                    <Text>{item.trxdtax}</Text>
                                </View>
                                <View style={{ 
                                    ...styles.tDataCell
                                }}>
                                    <Text>{item.trxdqty}</Text>
                                </View>
                            </View>
                        ))}
                    </View>
                </Page>
            </Document>
        </PDFViewer>
    );
};

export default MasterReportPdf;
