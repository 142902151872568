import React, { version } from 'react';
import { Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import { PDFViewer } from '@react-pdf/renderer';

const getDateFormat = (dateStr) => {
    const dateParts = dateStr.split(' ');
    const dateArr = dateParts[0].split('-');
    return dateArr[1] + '-' + dateArr[0] + '-' + dateArr[2];
};

const styles = StyleSheet.create({
    // pdfTable: {
    //     width: '100%',
    // },
    tableHeader: {
        fontWeight: '700',
        fontSize: '16px',
        textAlign: 'center',
        borderBottom: '1px solid #dee2e6',
        color: '#000',
        padding: 8,
    },
    title: {
        height: 29,
        textAlign: 'center',
        fontSize: 16,
        fontWeight: 'bold',
        padding: 12,
        color: '#000',
    },
    tCellRow: {
        display: 'flex',
        flexDirection: 'row',
        borderBottom: '1px solid #dee2e6',
    },
    tCell: {
        flex: 1,
        textAlign: 'center',
        fontSize: '8px',
        borderRight: '1px solid #dee2e6',
    },
    t2Cell: {
        flex: 2,
        textAlign: 'center',
        fontSize: '8px',
        borderRight: '1px solid #dee2e6',
    },
    tDataCell: {
        flex: 1,
        textAlign: 'center',
        fontSize: '7px',
        borderRight: '1px solid #dee2e6',
        overflow: 'hidden',
    },
    t2DataCell: {
        flex: 2,
        textAlign: 'center',
        fontSize: '7px',
        borderRight: '1px solid #dee2e6',
        overflow: 'hidden',
    },
    tInnerCell: {
        padding: '16px 4px 8px 4px',
    },
    mb4: {
        marginBottom: '4px',
    },
    tDataCellPadding: {
        padding: '4px 0px',
    },
    borderRight: {
        borderRight: '1px solid #dee2e6',
    },
    pv10: {
        padding: '10px 0px',
    },
    f10: {
        fontSize: 10,
    },
});

const getCurrentDateTime = () => {
    const now = new Date();
    const twoDigit = (num) => (num < 10 ? `0${num}` : num);
    const year = now.getFullYear();
    const month = twoDigit(now.getMonth() + 1); // Months are zero-based
    const day = twoDigit(now.getDate());
    const hours = twoDigit(now.getHours());
    const minutes = twoDigit(now.getMinutes());
    const seconds = twoDigit(now.getSeconds());

    const formattedDateTime = `${month}-${day}-${year} ${hours}:${minutes}:${seconds}`;

    return formattedDateTime;
}

const getTerminalInfo = (terminalInfo) => {
    const { additionalfields } = terminalInfo
    const { birinfo } = additionalfields
    return birinfo.activate ? birinfo : {}
}

const addSpaceEveryNCharacters = (inputString, n) => {
    const regex = new RegExp(`(.{1,${n}})`, 'g');
    return inputString.replace(regex, '$1 ');
}

const getDigit = (value) => {
    const valueArr = value.split('.')
    return valueArr[0]
}

const getFloat = (value) => {
    const valueArr = value.split('.')
    return '.'+ valueArr[1]
}

const getVersionName = versionList => {
    return versionList[0].translation['system.pos.product.header']
}

const TerminalSummaryPdf = (props) => {
    const { data, storeInfo, terminalInfo, reportDate, versionList } = props;
    const { additionalfields } = storeInfo;
    const terminalData = getTerminalInfo(terminalInfo)
    const storeData = additionalfields.birinfo ? additionalfields.birinfo : {}
    const version = versionList.length > 0 ? getVersionName(versionList) : 'Digital eXperience (DX) Version 1.0'
    return (
        <PDFViewer width="100%" height="600">
            <Document>
                <Page size="A4" style={styles.page} orientation="landscape">
                    <View
                        style={{
                            fontSize: '10px',
                            textAlign: 'center',
                            marginTop: '20px',
                        }}
                    >
                        <Text style={styles.mb4}>{storeData.businessname}</Text>
                        {storeData.storetype !== '' && (
                            <Text style={styles.mb4}>{storeData.storetype}</Text>
                        )}
                        <Text style={styles.mb4}>{storeData.registeredname}</Text>
                        <Text style={styles.mb4}>{storeData.branchaddress}</Text>
                        <Text style={styles.mb4}>{storeData.tin}</Text>
                        <Text style={styles.mb4}>
                            Report Date: {reportDate}
                        </Text>
                    </View>
                    <View
                        style={{
                            fontSize: '10px',
                            marginTop: '20px',
                            marginLeft: '20px',
                        }}
                    >
                        <Text style={styles.mb4}>
                            {version}
                        </Text>
                        <Text style={styles.mb4}>
                            Branch: {storeData.branchcode}
                        </Text>
                        <Text style={styles.mb4}>Terminal: {terminalInfo.terminalid}</Text>
                        <Text style={styles.mb4}>
                            Serial No: {terminalData.serialno}
                        </Text>
                        <Text style={styles.mb4}>
                            MIN: {terminalData.min}
                        </Text>
                        <Text style={styles.mb4}>
                            PTU: {terminalData.ptu}
                        </Text>
                        <Text style={styles.mb4}>
                            Date and Time Generated: {getCurrentDateTime()}
                        </Text>
                        <Text style={styles.mb4}>
                            Generated By: {localStorage.getItem('userId')}
                        </Text>
                    </View>
                    <View style={styles.pdfTable}>
                        <View>
                            <Text style={styles.tableHeader}>
                                BIR SALES SUMMARY  REPORT
                            </Text>
                        </View>
                        <View style={styles.tCellRow}>
                            <View
                                style={{
                                    ...styles.tCell,
                                    backgroundColor: '#A6A6A6',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <Text>Date</Text>
                            </View>
                            <View
                                style={{
                                    ...styles.tCell,
                                    backgroundColor: '#A6A6A6',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <Text>Ending SI/OR No.</Text>
                            </View>
                            <View
                                style={{
                                    ...styles.tCell,
                                    backgroundColor: '#A6A6A6',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}
                            >
                                <Text>Beginning SI/OR No.</Text>
                            </View>
                            
                            <View
                                style={{
                                    ...styles.t2Cell,
                                    backgroundColor: '#00B0F0',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <Text>Grand Accum. Sales Ending Balance</Text>
                            </View>
                            <View
                                style={{
                                    ...styles.t2Cell,
                                    backgroundColor: '#00B0F0',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <Text>Grand Accum. Beg.Balance</Text>
                            </View>
                            <View
                                style={{
                                    ...styles.tCell,
                                    backgroundColor: '#00B0F0',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <Text>
                                    Sales Issued w/ Manual SI/OR (per RR
                                    16-2018)
                                </Text>
                            </View>
                            <View
                                style={{
                                    ...styles.tCell,
                                    backgroundColor: '#00B0F0',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <Text>Gross Sales for the Day</Text>
                            </View>
                            <View
                                style={{
                                    ...styles.tCell,
                                    backgroundColor: '#FFFF00',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <Text>VATable Sales</Text>
                            </View>
                            <View
                                style={{
                                    ...styles.tCell,
                                    backgroundColor: '#FFFF00',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <Text>VAT Amount</Text>
                            </View>
                            <View
                                style={{
                                    ...styles.tCell,
                                    backgroundColor: '#FFFF00',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <Text>VAT Exempt Sales</Text>
                            </View>
                            <View
                                style={{
                                    ...styles.tCell,
                                    backgroundColor: '#FFFF00',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <Text>Zero Rated Sales</Text>
                            </View>
                            <View
                                style={{
                                    ...styles.tCell,
                                    display: 'flex',
                                    flex: '8',
                                    flexDirection: 'column',
                                    backgroundColor: '#ffd966',
                                }}
                            >
                                <View
                                    style={{
                                        textAlign: 'center',
                                        width: '100%',
                                    }}
                                >
                                    <View
                                        style={{
                                            ...styles.pv10,
                                            borderBottom: '1px solid #dee2e6',
                                        }}
                                    >
                                        <Text>Deductions</Text>
                                    </View>
                                    <View>
                                        <View
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                            }}
                                        >
                                            <View
                                                style={{
                                                    ...styles.borderRight,
                                                    flex: 5,
                                                }}
                                            >
                                                <View
                                                    style={{
                                                        ...styles.pv10,
                                                        borderBottom:
                                                            '1px solid #dee2e6',
                                                    }}
                                                >
                                                    <Text>Discount</Text>
                                                </View>
                                                <View
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                    }}
                                                >
                                                    <View
                                                        style={{
                                                            ...styles.borderRight,
                                                            ...styles.pv10,
                                                            flex: 1,
                                                        }}
                                                    >
                                                        <Text>SC</Text>
                                                    </View>
                                                    <View
                                                        style={{
                                                            ...styles.borderRight,
                                                            ...styles.pv10,
                                                            flex: 1,
                                                        }}
                                                    >
                                                        <Text>PWD</Text>
                                                    </View>
                                                    <View
                                                        style={{
                                                            ...styles.borderRight,
                                                            ...styles.pv10,
                                                            flex: 1,
                                                        }}
                                                    >
                                                        <Text>NAAC</Text>
                                                    </View>
                                                    <View
                                                        style={{
                                                            ...styles.borderRight,
                                                            ...styles.pv10,
                                                            flex: 1,
                                                        }}
                                                    >
                                                        <Text>Solo Parent</Text>
                                                    </View>
                                                    <View
                                                        style={{
                                                            ...styles.pv10,
                                                            flex: 1,
                                                        }}
                                                    >
                                                        <Text>Others</Text>
                                                    </View>
                                                </View>
                                            </View>
                                            <View
                                                style={{
                                                    flex: 3,
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                }}
                                            >
                                                <View
                                                    style={{
                                                        ...styles.borderRight,
                                                        ...styles.pv10,
                                                        flex: 1,
                                                        display: 'flex',
                                                        justifyContent:
                                                            'center',
                                                    }}
                                                >
                                                    <Text>Returns</Text>
                                                </View>
                                                <View
                                                    style={{
                                                        ...styles.borderRight,
                                                        ...styles.pv10,
                                                        flex: 1,
                                                        display: 'flex',
                                                        justifyContent:
                                                            'center',
                                                    }}
                                                >
                                                    <Text>Voids</Text>
                                                </View>
                                                <View
                                                    style={{
                                                        ...styles.borderRight,
                                                        ...styles.pv10,
                                                        flex: 1,
                                                        display: 'flex',
                                                        justifyContent:
                                                            'center',
                                                    }}
                                                >
                                                    <Text>
                                                        Total Deductions
                                                    </Text>
                                                </View>
                                            </View>
                                        </View>
                                    </View>
                                </View>
                            </View>
                            <View
                                style={{
                                    ...styles.tCell,
                                    display: 'flex',
                                    flex: '7',
                                    flexDirection: 'column',
                                    backgroundColor: '#92D050',
                                }}
                            >
                                <View
                                    style={{
                                        textAlign: 'center',
                                        width: '100%',
                                    }}
                                >
                                    <View
                                        style={{
                                            ...styles.pv10,
                                            borderBottom: '1px solid #dee2e6',
                                        }}
                                    >
                                        <Text>Adjustment on VAT</Text>
                                    </View>
                                    <View>
                                        <View
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                            }}
                                        >
                                            <View
                                                style={{
                                                    ...styles.borderRight,
                                                    flex: 4,
                                                }}
                                            >
                                                <View
                                                    style={{
                                                        ...styles.pv10,
                                                        borderBottom:
                                                            '1px solid #dee2e6',
                                                    }}
                                                >
                                                    <Text>Discount</Text>
                                                </View>
                                                <View
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                    }}
                                                >
                                                    <View
                                                        style={{
                                                            ...styles.borderRight,
                                                            ...styles.pv10,
                                                            flex: 1,
                                                        }}
                                                    >
                                                        <Text>SC</Text>
                                                    </View>
                                                    <View
                                                        style={{
                                                            ...styles.borderRight,
                                                            ...styles.pv10,
                                                            flex: 1,
                                                        }}
                                                    >
                                                        <Text>PWD</Text>
                                                    </View>
                                                    <View
                                                        style={{
                                                            ...styles.borderRight,
                                                            ...styles.pv10,
                                                            flex: 1,
                                                        }}
                                                    >
                                                        <Text>Solo Parent</Text>
                                                    </View>
                                                    <View
                                                        style={{
                                                            ...styles.pv10,
                                                            flex: 1,
                                                        }}
                                                    >
                                                        <Text>Others</Text>
                                                    </View>
                                                </View>
                                            </View>
                                            <View
                                                style={{
                                                    flex: 3,
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                }}
                                            >
                                                <View
                                                    style={{
                                                        ...styles.borderRight,
                                                        ...styles.pv10,
                                                        flex: 1,
                                                        display: 'flex',
                                                        justifyContent:
                                                            'center',
                                                    }}
                                                >
                                                    <Text>VAT on Returns</Text>
                                                </View>
                                                <View
                                                    style={{
                                                        ...styles.borderRight,
                                                        ...styles.pv10,
                                                        flex: 1,
                                                        display: 'flex',
                                                        justifyContent:
                                                            'center',
                                                    }}
                                                >
                                                    <Text>Others</Text>
                                                </View>
                                                <View
                                                    style={{
                                                        ...styles.borderRight,
                                                        ...styles.pv10,
                                                        flex: 1,
                                                        display: 'flex',
                                                        justifyContent:
                                                            'center',
                                                    }}
                                                >
                                                    <Text>
                                                    Total VAT Adjustment
                                                    </Text>
                                                </View>
                                            </View>
                                        </View>
                                    </View>
                                </View>
                            </View>
                            <View
                                style={{
                                    ...styles.tCell,
                                    backgroundColor: '#A6A6A6',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <Text>VAT Payable</Text>
                            </View>
                            <View
                                style={{
                                    ...styles.tCell,
                                    backgroundColor: '#A6A6A6',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <Text>Net Sales</Text>
                            </View>
                            <View
                                style={{
                                    ...styles.tCell,
                                    backgroundColor: '#A6A6A6',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <Text>Sales Overrun /Overflow</Text>
                            </View>
                            <View
                                style={{
                                    ...styles.tCell,
                                    backgroundColor: '#A6A6A6',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <Text>Total Income</Text>
                            </View>
                            <View
                                style={{
                                    ...styles.tCell,
                                    backgroundColor: '#A6A6A6',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <Text>Reset Counter</Text>
                            </View>
                            <View
                                style={{
                                    ...styles.tCell,
                                    backgroundColor: '#A6A6A6',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <Text>Z -Counter</Text>
                            </View>
                            <View
                                style={{
                                    ...styles.tCell,
                                    backgroundColor: '#A6A6A6',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <Text>Remarks</Text>
                            </View>
                        </View>
                        {data.map((item, index) => (
                            <View key={index} style={{ ...styles.tCellRow, ...styles.f10 }}>
                                <View style={{ 
                                    ...styles.tDataCell
                                }}>
                                    <Text>{addSpaceEveryNCharacters(getDateFormat(item.trxdate), 5)}</Text>
                                </View>
                                <View style={{ ...styles.tDataCell}}>
                                    <Text>{addSpaceEveryNCharacters(item.invoiceresetno+'-'+item.invoicenotxtfrom, 5)}</Text>
                                </View>
                                <View style={{ ...styles.tDataCell }}>
                                    <Text>{addSpaceEveryNCharacters(item.invoiceresetno+'-'+item.invoicenotxttill, 5)}</Text>
                                </View>
                                <View style={{ ...styles.t2DataCell }}>
                                        <Text>{item.newgtotal_saleamt.toFixed(2)}</Text>
                                </View>
                                <View style={{ ...styles.t2DataCell }}>
                                    <Text>{item.oldgtotal_saleamt.toFixed(2)}</Text>
                                </View>
                                <View style={{ ...styles.tDataCell }}>
                                    <Text></Text>
                                </View>
                                <View style={{ ...styles.tDataCell }}>
                                    <Text>{addSpaceEveryNCharacters(getDigit(item.grosssales.toFixed(2).toString()), 5)}</Text>
                                    <Text>{getFloat(item.grosssales.toFixed(2).toString())}</Text>
                                </View>
                                <View style={{ ...styles.tDataCell }}>
                                    <Text>{addSpaceEveryNCharacters(getDigit(item.vatablesales.toFixed(2).toString()), 5)}</Text>
                                    <Text>{getFloat(item.vatablesales.toFixed(2).toString())}</Text>
                                </View>
                                <View style={{ ...styles.tDataCell }}>
                                    <Text>{addSpaceEveryNCharacters(getDigit(item.vatgrossamount.toFixed(2).toString()), 5)}</Text>
                                    <Text>{getFloat(item.vatgrossamount.toFixed(2).toString())}</Text>
                                </View>
                                <View style={{ ...styles.tDataCell }}>
                                    <Text>{getDigit(item.vatexemptsales.toFixed(2).toString())}</Text>
                                    <Text>{getFloat(item.vatexemptsales.toFixed(2).toString())}</Text>
                                </View>
                                <View style={{ ...styles.tDataCell }}>
                                    <Text>{getDigit(item.zeroratedsales.toFixed(2).toString())}</Text>
                                    <Text>{getFloat(item.zeroratedsales.toFixed(2).toString())}</Text>
                                    {/* <Text>{getDigit('0.00')}</Text>
                                    <Text>{getFloat('0.00')}</Text> */}
                                </View>
                                <View style={{ ...styles.tDataCell }}>
                                    <Text>{getDigit(item.deduction_sc.toFixed(2).toString())}</Text>
                                    <Text>{getFloat(item.deduction_sc.toFixed(2).toString())}</Text>
                                </View>
                                <View style={{ ...styles.tDataCell }}>
                                    <Text>{getDigit(item.deduction_pwd.toFixed(2).toString())}</Text>
                                    <Text>{getFloat(item.deduction_pwd.toFixed(2).toString())}</Text>
                                </View>
                                <View style={{ ...styles.tDataCell }}>
                                    <Text>{getDigit(item.deduction_naac.toFixed(2).toString())}</Text>
                                    <Text>{getFloat(item.deduction_naac.toFixed(2).toString())}</Text>
                                </View>
                                <View style={{ ...styles.tDataCell }}>
                                    <Text>{getDigit(item.deduction_sp.toFixed(2).toString())}</Text>
                                    <Text>{getFloat(item.deduction_sp.toFixed(2).toString())}</Text>
                                </View>
                                <View style={{ ...styles.tDataCell }}>
                                    <Text>{getDigit(item.deduction_regular.toFixed(2).toString())}</Text>
                                    <Text>{getFloat(item.deduction_regular.toFixed(2).toString())}</Text>
                                </View>
                                <View style={{ ...styles.tDataCell }}>
                                    <Text>{getDigit(item.deduction_return.toFixed(2).toString())}</Text>
                                    <Text>{getFloat(item.deduction_return.toFixed(2).toString())}</Text>
                                </View>
                                <View style={{ ...styles.tDataCell }}>
                                    <Text>{getDigit(item.deduction_void.toFixed(2).toString())}</Text>
                                    <Text>{getFloat(item.deduction_void.toFixed(2).toString())}</Text>
                                </View>
                                <View style={{ ...styles.tDataCell }}>
                                    <Text>{getDigit(item.deduction_total.toFixed(2).toString())}</Text>
                                    <Text>{getFloat(item.deduction_total.toFixed(2).toString())}</Text>
                                </View>
                                <View style={{ ...styles.tDataCell }}>
                                    <Text>{getDigit(item.vatadj_sc.toFixed(2).toString())}</Text>
                                    <Text>{getFloat(item.vatadj_sc.toFixed(2).toString())}</Text>
                                </View>
                                <View style={{ ...styles.tDataCell }}>
                                    <Text>{getDigit(item.vatadj_pwd.toFixed(2).toString())}</Text>
                                    <Text>{getFloat(item.vatadj_pwd.toFixed(2).toString())}</Text>
                                </View>
                                <View style={{ ...styles.tDataCell }}>
                                    <Text>{getDigit(item.vatadj_sp.toFixed(2).toString())}</Text>
                                    <Text>{getFloat(item.vatadj_sp.toFixed(2).toString())}</Text>
                                </View>
                                <View style={{ ...styles.tDataCell }}>
                                    <Text>{getDigit(item.vatadj_regular.toFixed(2).toString())}</Text>
                                    <Text>{getFloat(item.vatadj_regular.toFixed(2).toString())}</Text>
                                </View>
                                <View style={{ ...styles.tDataCell }}>
                                    <Text>{getDigit(item.vatadj_return.toFixed(2).toString())}</Text>
                                    <Text>{getFloat(item.vatadj_return.toFixed(2).toString())}</Text>
                                </View>
                                <View style={{ ...styles.tDataCell }}>
                                    <Text>{getDigit(item.vatadj_void.toFixed(2).toString())}</Text>
                                    <Text>{getFloat(item.vatadj_void.toFixed(2).toString())}</Text>
                                </View>
                                <View style={{ ...styles.tDataCell }}>
                                    <Text>{getDigit(item.vatadjustment.toFixed(2).toString())}</Text>
                                    <Text>{getFloat(item.vatadjustment.toFixed(2).toString())}</Text>
                                </View>
                                <View style={{ ...styles.tDataCell }}>
                                    <Text>{addSpaceEveryNCharacters(getDigit(item.vatamount.toFixed(2).toString()), 5)}</Text>
                                    <Text>{getFloat(item.vatamount.toFixed(2).toString())}</Text>
                                </View>
                                <View style={{ ...styles.tDataCell }}>
                                    <Text>{addSpaceEveryNCharacters(getDigit(item.netsales.toFixed(2).toString()), 5)}</Text>
                                    <Text>{getFloat(item.netsales.toFixed(2).toString())}</Text>
                                </View>
                                <View style={{ ...styles.tDataCell }}>
                                    <Text>{addSpaceEveryNCharacters(getDigit(item.saleoverrunamt.toFixed(2).toString()), 5)}</Text>
                                    <Text>{getFloat(item.saleoverrunamt.toFixed(2).toString())}</Text>
                                </View>
                                <View style={{ ...styles.tDataCell }}>
                                    <Text>{addSpaceEveryNCharacters(getDigit(item.totalincome.toFixed(2).toString()), 5)}</Text>
                                    <Text>{getFloat(item.totalincome.toFixed(2).toString())}</Text>
                                </View>
                                <View style={{ ...styles.tDataCell }}>
                                    <Text>{item.invoiceresetno}</Text>
                                </View>
                                <View style={{ ...styles.tDataCell }}>
                                    <Text>{item.resetno}</Text>
                                </View>
                                <View style={{ ...styles.tDataCell }}>
                                    <Text></Text>
                                </View>
                            </View>
                        ))}
                    </View>
                </Page>
            </Document>
        </PDFViewer>
    );
};

export default TerminalSummaryPdf;
